.nozzle-tooltip-wrapper {
    position: relative;
}

.nozzle-tooltip {
    display: none;
    position: absolute;
    left: 0rem;
    width: 25rem;
    background-color: $light;
    box-shadow: 0 20px 50px -5px rgba(63,96,116,0.5);
    padding: 2rem;
    margin: 0 auto;
    z-index: 502;
    user-select: none;

    &-link {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1;

        i {
            font-weight: 600;
        }
    }

    i.icon-plus {
        transform: rotate(45deg);
        font-size: 2.6rem;
        color: #000;
        position: absolute;
        right: 10px;
        top: 5px;
        cursor: pointer;
    }

    i.icon-double-arrow {
        color: $spray-lt-blue;
    }

    &-title {
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    &-rule {
        border-top: 6px solid $spray-red-orange;
        width: 0;
        text-align: left;
        margin: 0 0 1rem;
        transition: width 0.3s ease-in;
    }

    &-rule.active {
        width: 60%;

        &:hover {
            width: 40%;
        }
    }

    &-text {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }

    ul {
        font-size: 1.4rem;
        font-weight: 400;
    }
}