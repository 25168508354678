/* ===== THIN-HERO-BANNER ===== */
.thin-hero-banner {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-top: 64px;
    @include media-breakpoint-up(xl) {
        margin-top: 8rem;
    }

    .banner-wrapper {
        width: 100%;
        padding: 2rem 16px;
        margin: 0 auto;
        position: relative;
        @include media-breakpoint-down(md) {
            max-width: 572px;
        }
        @include media-breakpoint-up(md) {
            max-width: 720px;
            padding-right: 20px;
            padding-left: 20px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 960px;
        }
        @include media-breakpoint-up(xl) {
            max-width: 1200px;
        }
        @include media-breakpoint-up(xlm) {
            max-width: 1400px;
        }
        @include media-breakpoint-up(xxl) {
            max-width: $max-width;
        }
    }

    .main-title {
        margin: 0;
        letter-spacing: .03em;
        text-transform: uppercase;
        text-align: center;
        color: white;
        @include media-breakpoint-down(md) {
            font-size: 32px;
            line-height: 40px;
        }
        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }

    @media (min-width: 1200px) {
        margin-top: 15rem;
    }
}