/* ===== VIDEO-FEATURE ===== */
.video-feature {
    display: flex;
    flex-direction: column;
    gap: 24px;
    font: 16px/1.5 $font-default;
    color: $color-night;
    @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
    }
    @include media-breakpoint-up(lg) {
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0 24px;
    }

    &-container {
        margin: 4rem 0;
        position: relative;
        z-index: 1;
        @include media-breakpoint-up(md) {
            margin: 5rem 0;
        }
        @include media-breakpoint-up(xl) {
            margin: 8rem 0;
        }
    }

    &.videoRight {
        /*
        &::after {
            @include pseudo;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            background: $color-maui;
        }
         */

    }

    &-top {
        width: 100%;
        order: 0;
        &:empty {display: none;}
    }

    &-image {
        width: 100%;
        min-height: 1px;
        margin: 0 auto;
        position: relative;
        display: block;
        order: 1;
        //align-self: flex-start;
        outline: none;
        transition: none;
        @include media-breakpoint-up(md) {
            width: 580px;
        }
        @include media-breakpoint-up(lg) {
            width: calc(50% - 12px);
            margin-bottom: 0;
            .videoRight & {order: 3;}
        }

        img, video, iframe {
            width: 100%;
            max-width: 100%;
            height: auto;
            //border-radius: 4px;
            display: block;
            position: relative;
            z-index: 1;
            @include user-select;
            @include user-drag;
        }

        &[data-fancybox="data-fancybox"] {
            &::before {
                @include pseudo;
                width: 70px;
                height: 46px;
                margin: auto;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 5;
                opacity: 1;
                transition: opacity $effect;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='163' height='108' fill='none'%3E%3Cpath fill='%23004B87' d='M162.102 56.6a236.077 236.077 0 0 1-.387 10.987c-.27 3.83-.642 7.85-1.115 12.062-.473 4.213-1.114 7.787-1.925 10.722-.946 3.319-2.702 6.191-5.269 8.616s-5.607 4.085-9.119 4.978c-3.107.766-8.14 1.372-15.098 1.819-6.957.447-14.016.798-21.177 1.053-8.376.256-17.36.383-26.952.383h-8.309c-2.567 0-5.505-.032-8.815-.096-3.31-.063-6.789-.159-10.436-.287l.128-.275a743.802 743.802 0 0 1-20.697-1.036c-6.958-.447-11.99-1.053-15.098-1.819-3.512-.893-6.552-2.553-9.119-4.978s-4.323-5.297-5.269-8.616c-.81-2.936-1.452-6.51-1.925-10.722A293.522 293.522 0 0 1 .405 67.33c-.203-3.455-.33-6.947-.38-10.474l-.025.01V53.61c0-4.723.135-9.382.405-13.977.27-3.83.642-7.85 1.115-12.062s1.114-7.786 1.925-10.722c.946-3.319 2.702-6.19 5.269-8.616 2.567-2.425 5.607-4.085 9.12-4.978 3.107-.766 8.139-1.372 15.097-1.819A744.507 744.507 0 0 1 54.108.383C62.484.128 71.468 0 81.06 0c9.592 0 18.576.128 26.952.383 7.161.255 14.22.606 21.177 1.053 6.958.447 11.991 1.053 15.098 1.819 3.512.893 6.552 2.553 9.119 4.978s4.323 5.297 5.269 8.616c.811 2.936 1.452 6.51 1.925 10.722.473 4.212.845 8.233 1.115 12.062.27 4.595.405 9.254.405 13.977v2.997l-.018-.007Z'/%3E%3Cpath fill='%23fff' d='m64.848 76.326 42.151-22.975-42.151-22.976v45.951Z'/%3E%3C/svg%3E") no-repeat center center;
                background-size: contain;
                @include media-breakpoint-up(md) {
                    width: 100px;
                    height: 70px;
                }
                @include media-breakpoint-up(lg) {
                    width: 120px;
                    height: 80px;
                }
                @include media-breakpoint-up(xxl) {
                    width: 163px;
                    height: 108px;
                }
            }
            &::after {
                @include pseudo;
                border-radius: 4px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 2;
                background: rgba($color-night, .25);
                opacity: 1;
                transition: opacity $effect;
            }

            &:hover, &:focus {
                &::after {opacity: .25;}
            }
        }

        .button-play {display: none;}
    }

    &-text {
        order: 4;
        letter-spacing: .0075em;
        @include media-breakpoint-up(md) {
            width: 580px;
            margin: 0 auto;
        }
        @include media-breakpoint-up(lg) {
            width: calc(50% - 12px);
            margin: 0;
            order: 3;
            .videoRight & {order: 2;}
        }
        @include media-breakpoint-up(xl) {
            padding-right: 40px;
            .videoLeft & {padding-left: 40px;}
            .videoRight & {padding-right: 40px;}
        }
        @include media-breakpoint-up(xxl) {
            .videoLeft & {padding-left: 80px;}
            .videoRight & {padding-right: 80px;}
        }

        &.textCenter {text-align: center;}
        &.textLeft {text-align: left;}
        &.textRight {text-align: right;}

        small {
            margin-bottom: 8px;
            display: block;
            font-size: 14px;
            line-height: 160%;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: .05em;
            color: $color-led-blue;
            @include media-breakpoint-up(xl) {
                margin-bottom: 16px;
                font-size: 16px;
            }
        }

        h3 {
            margin: 0 0 2rem;
            font-size: 24px;
            line-height: 28px;
            font-weight: bold;
            letter-spacing: .005em;
            color: $color-night;
            @include media-breakpoint-up(md) {
                margin-bottom: 32px;
                font-size: 28px;
                line-height: 32.8px;
            }
            @include media-breakpoint-up(xl) {
                margin-bottom: 42px;
            }

            &.eyebrow {
                border: 0;
                color: $dark;
                font-size: 2.3rem;
                font-weight: 500;
                letter-spacing: .24px;
                line-height: 1;
                margin: 0 0 1rem;
                padding: 0;
                text-transform: none;
                @include media-breakpoint-up(md) {
                    font-size: 2.6rem;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 3.2rem;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 3.4rem;
                }
            }
        }

        p {
            &:last-child {margin-bottom: 0;}
        }

        &-copy {
            display: block;
            & > br {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
            & > br:last-of-type {
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }

        .button-blue {
            padding-top: 18px;
            padding-bottom: 18px;
            border: 1.5px solid $color-led-blue;
            color: $color-navy !important;
            text-align: center;
            background: transparent;
            box-shadow: inset 0 -3px 0 rgba(black, .2);
            @include media-breakpoint-up(md) {
                margin-top: 12px;
            }
            @include media-breakpoint-up(xl) {
                min-width: 402px;
                margin-top: 23px;
            }
        }

        & > *:last-child, &-copy > *:last-child {
            margin-bottom: 0 !important;
        }
    }

    &-link {
        width: 100%;
        height: 0;
        margin-top: -24px;
        position: relative;
        z-index: 10;
        order: 2;
        @include media-breakpoint-up(lg) {
            margin-top: 0;
            order: 4;
        }
    }

    &-bottom {
        width: 100%;
        //height: 0;
        //margin-top: -24px;
        position: relative;
        z-index: 10;
        order: 3;
        @include media-breakpoint-up(lg) {
            //margin-top: 0;
            .videoRight & {order: 4;}
        }
    }

    .video-feature-text-action, .video-feature-link-action {
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 24px;
        }

        a {
            padding: 12px 24px;
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            color: $color-black;
            text-decoration: none;
            white-space: nowrap;
            background: rgba($color-white, .7);
            transform: translateX(-50%);
            transition: none;

            &.has-arrow {
                &::after {display: none !important;}
            }
        }

        i {
            color: $link-color;
            font-size: inherit;
            font-weight: 600;
            line-height: inherit;
        }
    }

    @include media-breakpoint-up(lg) {
        &.videoLeft {
            .video-feature-text-action, .video-feature-link-action {
                right: 50%;
            }
        }
        &.videoRight {
            .video-feature-text-action, .video-feature-link-action {
                left: 50%;
            }
        }
    }
}

.video-media {
    width: 100%;
    height: 100%;
    grid-area: media;

    &-description {
        grid-area: description;
        border-bottom: 1px $spray-blue dotted;
    }
}

.video-playlist {
    max-height: 40rem;
    grid-area: list;
    overflow-y: auto;
    @include media-breakpoint-up(md) {
        max-height: unset;
    }

    &-container {
        position: relative;
        background-color: $color-white;
        display: grid;
        padding: 4rem;
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
        grid-template-rows: auto auto auto;
        grid-template-areas: "media" "description" "list";

        &::after {
            @include pseudo;
            position: absolute;
            bottom: 2px;
            left: 50%;
            width: 95%;
            transform: translate(-50%, 0);
            border-bottom: 1px dotted $light-gray;
        }

        @include media-breakpoint-up(md) {
            height: 56rem;
            padding: 8rem 0 8rem 0;
            grid-template-columns: auto 40vw 35vw auto;
            grid-template-rows: auto auto;
            grid-template-areas: ". media description ." ". media list .";
        }

        .video-feature-image {
            padding: 0;
        }
    }

    .item {
        display: flex;
        padding: 1rem 0 1rem;
        justify-content: start;
        color: $dark;

        &:first-of-type {padding-top: 0;}

        &:hover {
            text-decoration: underline;
            background-color: $lighter-gray;
            cursor: pointer;
        }

        .thumbnail {
            position: relative;
            width: 130px;
            pointer-events: none;

            img, video {
                min-width: 130px;
                width: 130px;
                height: 73px;
                object-fit: cover;
            }

            .duration {
                background-color: rgba(black, .4);
                color: $lighter-gray;
                font-size: 1.3rem;
                display: none;
                position: absolute;
                top: 53px;
                right: 0;
                padding: .2rem;
            }
        }

        .title {
            padding-left: 2rem;
            font-weight: 500;
            pointer-events: none;
        }

        &.active {
            color: $gray;
        }
    }
}

.video-link-content {
    position: relative;
    @include media-breakpoint-up(md) {
        height: 40rem;
    }

    img, video {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        object-position: top;
    }
}