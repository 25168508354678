.careers {
    &-form {
        border-radius: 8px;
        background: $color-mist;

        h3 {
            margin-bottom: 4rem;
        }
    }

    &-bottom {
        margin: 0 auto 0;
        max-width: 850px;
        padding: 0 2rem 3rem;
        @media #{$medium-up} { padding-bottom: 6rem; }
        @media #{$large-up} { padding-bottom: 8rem; }
    }

    &-bottom-desc-container {
        padding: 4rem 0 2rem;
        @media #{$large-up} { padding: 7.5rem 0 3.5rem; }
    }

    &-bottom-desc {
        color: $dark;
        font-size: 1.8rem;
        font-weight: 600;
        @media #{$medium-up} { font-size: 2rem; }
        @media #{$large-up} { font-size: 2.4rem; }
    }

    &-bottom-header {
        font-size: 2.8rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
        @media #{$medium-up} { font-size: 3.4rem; }
        @media #{$large-up} { font-size: 4rem; }
    }

    .spray-form {
        padding: 0;

        /*
        input,textarea {
            margin-bottom: 2rem;
        }

        textarea { background-color: #ffffff; }

        label.interest, label.checkbox {
            margin-bottom: 2rem;
        } 
        label.checkbox { 
            color: $spray-dk-blue-gray; 
            font-weight: 400; 
            margin-left: -5px;
        }
        .checkbox::before {
            font-size: 2.8rem;
            top: -10px;
        }
        [type='checkbox']:not(:checked)+label, [type='checkbox']:checked+label {
            padding-left: 4rem !important;
        }
         */


        .file-upload {
            margin: 2.5rem 0;

            label {
                margin-bottom: 0;
                vertical-align: middle;
            }

            i {
                margin-top: -1px;
                font-size: 2.4rem;
                color: $color-night;
                display: inline-block;
                vertical-align: middle;
            }

            a {
                cursor: pointer;
                font-weight: 500;
                color: $color-navy;
                text-decoration: none;
                display: inline-block;
                vertical-align: middle;
                transition: color $effect;

                &:hover, &:focus {
                    color: $color-night;
                    text-decoration: none;
                }
            }
        }

        *[id$="CaptchaValue_wrapper"], .g-recaptcha {
            margin-bottom: 3rem;
            margin-left: -1rem;
            @media screen and (min-width: 375px) { margin-left: 0; }
        }

        &-field:last-child {
            margin-bottom: 0 !important;
        }

        .validation-errors {
            display: none;
            .validation-message + .validation-message {
                display: none !important;
            }
        }
    }

    &-bottom-form-desc {
        font-size: 1.6rem;
        @media #{$medium-up} {
            font-size: 1.8rem;
            margin-bottom: 3rem;
        }
        @media #{$large-up} {
            font-size: 2rem;
            margin-bottom: 4.5rem;
        }
    }
    .cards-default-container {
        padding: 3rem 0;
        @media #{$medium-up} { padding: 6rem 0; }
        @media #{$large-up}  { padding: 8rem 0; }
    }

    .video-feature-container.left {
        @media #{$medium-up} { padding-bottom: 0; }
    }
    @media #{$medium-up} {
        &-form-inner {
            display: flex;
        }
        &-form-item {
            padding-right: 4rem;
            width: 50%;
            &:nth-of-type(2n+2) { padding-right: 0; }
        }
        &-form-inner .spray-form-field {
            padding-right: 4rem;
            width: 50%;

            &:nth-of-type(2n+2) {
                padding-right: 0;
            }
        }
    }

    input[type='file'] {
        display: none;
    }
}