.spray-form {
    .styled-form-1 {
        background: #183446;
        padding: 15px 15px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .spray-form-field {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .lets-talk {
            width: 100%;
        }

        input {
            border-radius: 5px;
            border: none;
            padding: 8px;
            width: 100%;
        }

        button {
            margin-top: 40px;
            padding: 10px 30px;
            border-radius: 4px;
            border: none;
            background: #eca426;
            color: white;
            width: 100%;
        }

        .checkbox {
            margin: 0px;
        }

        .checkbox label {
            color: white;
        }

        label {
            color: white;
            padding-bottom: 3px;
        }

        p {
            color: white;
        }

        span {
            color: white;
            font-size: large;
            margin-bottom: 3rem;
        }
    }
}