/* ===== STAT-BAR ===== */
.stat-bar {
    margin: 4rem 0;
    @include media-breakpoint-up(md) {
        margin: 5rem 0;
    }
    @include media-breakpoint-up(xl) {
        margin: 8rem 0;
    }

    &-wrapper {
        text-align: center;
    }

    &-stat {
        margin: 0;
        display: block;
        font-size: 36px;
        line-height: 46px;
        font-weight: 800;
        color: $spray-blue-alt-1;
        letter-spacing: -0.01em;
    }

    &-copy {
        margin: 16px 0 0;
        font-size: 21px;
        line-height: 25px;
        font-weight: 500;

        &-action {
            margin-top: 31px;
            display: flex;
            justify-content: center;

            a {
                padding: 16px;
                border-radius: 4px;
                display: block;
                font: 600 13px/18px $font-default;
                letter-spacing: .005em;
                color: $color-white !important;
                text-align: center;
                text-transform: capitalize;
                background: $color-navy;
                transition: background-color $effect;
                box-shadow: inset 0 -4px 0 rgba(black, .2);
                @include media-breakpoint-up(xss) {
                    padding-right: 32px;
                    padding-left: 32px;
                    letter-spacing: .05em;
                }
                @include media-breakpoint-up(sm) {
                    padding-right: 42px;
                    padding-left: 42px;
                    letter-spacing: .075em;
                }
                @include media-breakpoint-up(md) {
                    font-size: 14px;
                }
                @include media-breakpoint-up(xl) {
                    padding-right: 90px;
                    padding-left: 90px;
                }

                &:hover, &:focus {
                    color: $color-white !important;
                    text-decoration: none;
                    background: #004975;
                }
            }

            .icon-double-arrow {
                display: none !important;
            }
        }
    }
}