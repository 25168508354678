/* ===== SIDEBAR-CARDS ===== */
.sidebar-cards {
    margin-bottom: 4rem;
    @include media-breakpoint-up(md) {
        margin-bottom: 6rem;
        background: $spray-lt-gray;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 8rem;
    }

    select.no-border {
        border: 0;
    }

    &-wrapper {
        margin: auto;
        max-width: 1600px;
        padding: .01rem 0 3rem;
        @include media-breakpoint-up(md) {
            padding: .01rem 2rem 3.5rem;
        }
        @include media-breakpoint-up(lg) {
            padding-bottom: 6.4rem;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-grid {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        &-item {
            width: 100%;
            margin-bottom: 1rem;
            @include media-breakpoint-up(md) {
                width: 50%;
                padding: 0 1rem;
                border: 0;
                margin-bottom: 2.4rem;
                &:last-child {margin-bottom: 0;}
            }
            @include media-breakpoint-up(lg) {
                width: 33%;
            }
            @include media-breakpoint-up(xl) {
                width: 25%;
            }

            .js-toggle-container {
                width: 100%;
                height: 58px;
                display: inline-block;
                position: absolute;
                z-index: 10;
                cursor: pointer;
                @include media-breakpoint-up(md) { pointer-events: none; }
            }

            .js-toggle {
                height: 58px;
                top: 0;
                right: 0;
                left: 0;
                @include media-breakpoint-up(md) {
                    display: none !important;
                }

                .expand-collapse {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: block;
                    position: absolute;
                    top: 8px;
                    right: 10px;
                    background: rgba(white, .25);
                    transition: background-color $effect;

                    &::before {
                        width: 18px;
                        height: 18px;
                        margin: auto;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath stroke='%2300ABE5' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M14.25 6.75 9 12 3.75 6.75'/%3E%3Cpath stroke='%23242F37' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M14.25 6.75 9 12 3.75 6.75'/%3E%3C/svg%3E") no-repeat center center !important;
                        transition: transform $effect !important;
                    }
                }
            }

            &-wrapper {
                height: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                font-size: 14px;
                line-height: 20px;
                text-align: left;
                background-color: $light;
                @include media-breakpoint-down(md) {
                    padding: 1px;
                    border-radius: 4px;
                    box-shadow: inset 0 0 0 1px rgba(63, 96, 115, .15);
                }
                @include media-breakpoint-up(md) {
                    box-shadow: 0 20px 50px -5px rgba(63, 96, 116, .15), inset 0 0 0 1px rgba(63, 96, 115, .15);
                }

                &.blue-background {
                    color: white;
                    background-color: $color-navy;
                    .sidebar-cards-grid-item-title h3 {
                        color: white;
                        &::after {background: #c77573;}
                    }
                    .js-toggle {
                        .expand-collapse::before {
                            filter: brightness(10000%);
                        }
                    }
                    &.active .js-toggle {
                        .expand-collapse::before {
                            transform: rotate(180deg) !important;
                            filter: brightness(10000%) !important;
                        }
                    }
                }

                &.active .js-toggle {
                    .expand-collapse::before {
                        filter: none;
                        transform: rotate(-180deg);
                    }
                }

                &.active &-content {
                    @media #{$xsmall},#{$small} { padding: 2rem; }
                }

                &.active .sidebar-cards-grid-item-title {
                    cursor: default;
                    h3:after {
                        opacity: 1;
                        width: 80px;
                    }
                }
                &.active .sidebar-cards-grid-item-content p {
                    opacity: 1;
                }
                &.active .sidebar-cards-grid-item-callout-action {
                    opacity: 1;
                }

            }

            &-content {
                @include media-breakpoint-down(md) {
                    padding: 15px;
                }
                @include media-breakpoint-up(md) {
                    padding: 2.4rem;
                }
                @include media-breakpoint-up(xl) {
                    padding: 2.4rem 3rem 3rem;
                }

                .phone {
                    color: #222;
                    font-weight: 500;
                }

                p {
                    opacity: 0;
                    @include media-breakpoint-up(md) {
                        opacity: 1;
                    }
                    &:last-child {margin-bottom: 0;}
                }

                a {
                    transition: color $effect;
                    &:hover {
                        color: $color-black;
                        text-decoration: none;
                    }
                }

                .no-margin {
                    margin-bottom: 0;
                }
            }

            &-callout-action {
                padding: 0;
                margin: 3rem 0 0;
                opacity: 0;
                transition: opacity .3s ease;
                @include media-breakpoint-up(md) {
                    opacity: 1;
                }

                .find-rep-form {
                    width: 100%;
                    display: block;
                    &:not(:last-of-type) {margin-bottom: 2.4rem;}

                    .find-country {
                        margin-bottom: 0 !important;
                        position: relative;

                        input.awesomplete {padding-right: 3.8rem;}

                        .awesomplete {
                            ul {
                                background: $light;
                                padding: .5rem .5rem 0 .5rem;
                                li {
                                    font-weight: 400;
                                    margin-bottom: .5rem;
                                }
                            }
                            ul::before {
                                background: transparent;
                                border: 0;
                            }
                            ul li mark {background-color: transparent;}
                            &:focus, &:active {
                                box-shadow: inset 0 0 0 2.5px $spray-lt-blue;
                            }

                            .icon-search {
                                background-color: transparent;
                                background-image: url('../images/template/magnifying.svg');
                                background-position: center 6px;
                                background-repeat: no-repeat;
                                border: 0;
                                box-shadow: none;
                                cursor: pointer;
                                font-size: 2rem;
                                height: 30px;
                                outline: 0;
                                position: absolute;
                                right: -10px;
                                text-indent: -9999px;
                                top: 21px;
                                width: 38px;
                                @include media-breakpoint-up(md) {
                                    top: 22px;
                                }
                                &:hover {
                                    background-color: transparent;
                                    box-shadow: none;
                                }
                                &:active, &:focus {
                                    background-color: transparent;
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                }
            }

            &-callout-result {
                display: none;
                margin: 2.4rem 0;
                font-size: 14px;
                &:last-of-type {margin-bottom: 0;}

                p {
                    margin-bottom: .8rem;
                    span {font-weight: 500;}
                    &:last-of-type {margin-bottom: 0;}
                }

                &.show {
                    display: block;
                }

                &-office {
                    border-bottom: 2px dotted rgba(255, 255, 255, .2);
                    padding-bottom: 1rem;
                    line-height: 1.5;
                }
            }

            &-copy {
                color: $spray-dk-blue-gray;
                display: none;
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1.425;
                margin: 0 3rem .6rem 2rem;
                opacity: .9;
                @include media-breakpoint-up(md) {
                    display: block !important;
                    margin: 0 3rem .6rem 3rem;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 1.8rem;
                    line-height: 1.333;
                    margin: .5rem 4rem 1.6rem 3rem;
                }
            }

            &-title {
                width: auto;
                display: block;
                font-weight: 500;
                cursor: default;
                &:hover {
                    border: 0;
                    text-decoration: none;
                }

                h3 {
                    margin: 0;
                    display: block;
                    position: relative;
                    @include media-breakpoint-down(md) {
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 500;
                        letter-spacing: 0;
                    }
                    @include media-breakpoint-up(md) {
                        margin: 0 0 2.6rem;
                    }

                    &:after {
                        width: 0;
                        height: 6px;
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -1.3rem;
                        opacity: 0;
                        transition: all .3s ease-in;
                        z-index: 1;
                        background-color: $color-green;
                        @include media-breakpoint-up(md) {
                            bottom: -1.5rem;
                            opacity: 1;
                            width: 80px;
                        }
                    }
                    &:hover:after { width: 100%; }
                    .active & { margin-bottom: 2.4rem; }
                }
            }

            &-copy {
                color: $spray-dk-blue-gray;
                display: none;
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1.425;
                margin: 0 0 .6rem 0;
                opacity: .9;
                @include media-breakpoint-up(md) {
                    display: block !important;
                    margin: 0 0 .6rem 0;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 1.6rem;
                    line-height: 1.4;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 1.8rem;
                    line-height: 1.333;
                    margin: .5rem 0 1.6rem 0;
                }
            }
            &-callout-action {
                display: none;
                @include media-breakpoint-up(md) {
                    display: block !important;
                }

                a {
                    position: relative;
                    color: $link-color;
                    font-weight: 600;
                    transition: color $effect;

                    &:hover {
                        color: $color-black;
                        text-decoration: none;
                    }
                    //margin: 1.5rem 0 3rem;
                }

                i {
                    color: $link-color;
                    font-weight: 600;
                }

                address {
                    font-style: normal;
                }
            }

            hr.js-slide {
                background: none;
                border-top: 2px dotted $lighter-gray;
                display: none;
                left: 0;
                margin-top: auto;
                position: relative;
                right: 0;
                width: 100%;
                @include media-breakpoint-up(md) {
                    display: block !important;
                }
            }

            span, p {
                &.js-slide {
                    display: none;
                    @include media-breakpoint-up(md) { display: block !important; }
                }
            }

            &-copy {
                padding-left: 1.5rem;

                p {
                    color: $spray-dk-blue-gray;
                    font-size: 1.4rem;
                    font-weight: 500;
                    line-height: 1.285;
                    margin: 0 0 .6rem;
                    opacity: .9;
                    @include media-breakpoint-up(xl) {
                        font-size: 1.6rem;
                        line-height: 1.125;
                    }
                }

                a {
                    color: $link-color;
                    display: block;
                    font-size: 1.4rem;
                    font-weight: 600;
                    line-height: 1.25;
                    @include media-breakpoint-up(xl) {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }
}