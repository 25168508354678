﻿.loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(63,96,116, 0.8);
    z-index: 5555;

    &.hidden {
        display: none;
    }

    &-wrapper {
        position: relative;
        margin-top: 30%;

        @media #{$large-up} {
            margin-top: 15%;
        }
    }

    &-spinner {
        border: 4px solid rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        border-top: 4px solid #ffffff;
        width: 6rem;
        height: 6rem;
        margin: 0 auto;
        -webkit-animation: spin 1s linear infinite; /* Safari */
        animation: spin 1s linear infinite;
    }

    &-message {
        margin-top: 1rem;
        text-align: center;
        color: #fff;
        font-size: 1.4rem;

        @media #{$large-up} {
            font-size: 1.8rem;
        }
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
