.landing-page {
    .article-copy {
        background-color: $spray-lt-gray;
        //padding: 2rem;

        &-flex {
            @include media-breakpoint-up(md) {
                max-width: 1200px;
                margin: 0 auto;
                display: flex;
            }

            &-main-col {
                @include media-breakpoint-up(md) {
                    width: 50%;
                    padding: 0 1.5rem 0 1rem;
                }
                @include media-breakpoint-up(lg) {
                    width: 60%;
                    padding: 0 3rem 0 1rem;
                }
            }
            &-secondary-col {
                padding-top: 2rem;
                @include media-breakpoint-up(md) {
                    width: 50%;
                    padding: 0 0 0 1.5rem;
                }
                @include media-breakpoint-up(lg) {
                    width: 40%;
                    padding: 0 0 0 3rem;
                }
            }

            .spray-form {
                @include media-breakpoint-up(md) {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    &-cta-link {
        &:hover {
            text-decoration: none;
        }
        h3 {
            color: $spray-blue;
            transition: all 0.2s ease-in;
        }
        .icon-double-arrow {
            color: $spray-lt-blue;
            font-size: 3.7rem;
            margin-bottom: 2rem;
            position: relative;
            top: -5px;
            transform: rotate(90deg);
            @include media-breakpoint-up(md) { transform: none; }
        }
    }

    .spray-form {
        &-header {
            margin-bottom: 4.5rem;
            position: relative;
            &::after {
                background-color: #d47070;
                bottom: -1.3rem;
                content: '';
                height: 6px;
                left: 0;
                opacity: 1;
                position: absolute;
                right: 0;
                width: 80px;
                z-index: 1;
            }
        }
        &-field { margin-bottom: 2rem; }
        .checkbox, .events-interest {
            margin-bottom: 2rem;
        }
        .checkbox label {
            color: $spray-dk-blue-gray;
            font-weight: 400;
            margin-left: -6px;
            padding-left: 4rem !important;
            &.consent-text {
                font-weight: 700;
            }
        }
        .checkbox label::before {
            font-size: 2.8rem;
            top: -10px;
        }

        button {
            margin-top: 1rem;
        }
    }
}