/* ===== ACCORDION ===== */
.accordion {
    max-width: 1068px;
    margin: 4rem auto;
    @include media-breakpoint-up(md) {
        margin: 5rem auto;
    }
    @include media-breakpoint-up(xl) {
        margin: 8rem auto;
    }

    &-header {
        text-align: left;
        &.headCenter {text-align: center;}
        &.headLeft {text-align: left;}
        &.headRight {text-align: right;}
    }

    &-grid-item {
        width: 100%;
        margin-bottom: 20px;
        &:last-child {margin-bottom: 0;}

        &-inner {
            border-radius: 4px;
            position: relative;
            text-align: left;
            background: white;
            box-shadow: inset 0 0 0 1px rgba($color-navy, .15);

            &.active {
                .js-toggle-container {
                    .js-toggle {
                        .expand-collapse {
                            background-color: $color-navy;
                            &::before {transform: rotate(90deg);background-color: white;opacity: 0;}
                            &::after {transform: rotate(180deg);background-color: white;}
                        }
                    }
                }
            }

            &.edit-mode {
                padding: 2rem;
            }
        }

        .js-toggle-container {
            width: 100%;
            height: 72px;
            display: inline-block;
            position: absolute;
            cursor: pointer;

            .js-toggle {
                width: 32px;
                height: 32px;
                border: 1px solid $color-navy;
                border-radius: 4px;
                display: block;
                position: absolute;
                top: 20px;
                right: 24px;
                z-index: 2;
                background: white;
                transition: background-color $effect;

                .expand-collapse {
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    transition: background-color $effect;

                    &::before, &::after {
                        @include pseudo;
                        width: 14px;
                        height: 2px;
                        border-radius: 2px;
                        margin: auto;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: $color-navy;
                        transition: background-color $effect, transform $effect, opacity $effect;
                    }

                    &::after {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        &-content {
            padding: 24px 20px;
        }

        &-title {
            margin-right: 60px;
            display: block;
            pointer-events: none;

            &:hover {
                text-decoration: none;
            }

            h3 {
                margin: 0;
                position: relative;
                font-weight: normal;
                line-height: 24px;
                letter-spacing: .005em;
            }
        }

        &-text {
            padding-left: 12px;
            border-left: 2px solid #a19f9d;
            margin: 16px 0 0 6px;
            letter-spacing: .0075em;
            display: none;
            @include media-breakpoint-up(md) {
                padding-left: 16px;
            }

            &::after {@include clearfix-new;}
            &.edit-mode {display: block;}
            & > *:last-child {margin-bottom: 0;}
        }

        &.reverse {
            .accordion-grid-item-title, .accordion-grid-item-content {color: white;}
            .accordion-grid-item-inner {background-color: $color-navy;}
        }
    }
}
