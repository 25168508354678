.blogs {

    &.top-left {
        position: absolute;
    }

    &.indented {
        top: 16px;
        left: 16px;
    }

    &.image-container {
        position: relative;
        text-align: center;
        color: white;
        min-height: 100px;

        img {
            margin: auto;
            display: block;
            max-width: 100%;
            width: 100%;
            height: auto;
        }
    }

    &.body-text {
        padding-left: 2em;
        padding-right: 2em;
    }

    &.pager-item {
    }

    &.recent-posts-widget {
        border: 1px solid lightgray;
        padding: 0 !important;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
    }

    &.related-posts-widget {
        border: 1px solid lightgray;
        padding: 0 !important;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
    }

    &.widget-header {
        background-color: #eef1f4;
        margin: 0 !important;
    }

    &.widget-header h2 {
        margin-bottom: 0px;
        padding: 15px 20px;
    }

    &.smallpost {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 20px 22px;
        border-bottom: 1px solid #dedede;
    }

    &.post-content {
        flex: 1;
        margin-left: 1rem;
    }

    &.post-thumbnail {
        width: 100px;
        height: 100px;
        object-fit: cover;
        margin-right: 22px;
    }

    &.post-title {
        margin-top: 5px;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 7px;
    }


    &.landscape {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }

    &.portrait {
        width: 100%;
        height: auto;
    }

    &.thumb {
        position: relative;
        display: block;
        overflow: hidden;
        height: 100px;
        width: 100px;
        min-width: 100px;
        min-height: 100px;
        margin-right: 22px;
    }

    &.thumbnail {
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    &.thumbnail-img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    /*&.thumbnail img.portrait {
        width: 100%;
        height: auto;
    }*/
    &.date-component-top {
        background-color: #0065a4;
        margin-bottom: 0px;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    &.date-component-bottom {
        background-color: #5e7c8e;
        padding-right: 0.1em;
        padding-left: 0.1em;
    }

    &.date-component-bottom h4 {
        color: #ffffff;
        padding: 0.1em;
    }

    &.date-component-date {
        margin-bottom: 0em;
        text-align: center;
        color: #ffffff;
    }

    &.entry-bottom {
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 30px;
        margin-top: 28px;
        margin-bottom: 28px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &.entry-bottom label {
        font-size: 17px;
        color: #222;
        font-family: futura-medium;
        margin-right: 10px;
    }

    &.entry-bottom .entry-tags {
        margin-right: 20px;
        margin-bottom: 15px;
        flex-grow: 1;
    }

    &.entry-tags a {
        background-color: #f5f5f5;
        border: 0;
        color: #777;
        display: inline-block;
        font-size: 13px !important;
        line-height: 34px;
        padding: 0 22px;
        margin-bottom: 10px;
        margin-right: 6px;
        -webkit-border-radius: 0;
        -khtml-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
    }

    &.entry-tags a:hover {
        color: #fff;
        background-color: #2897DC;
        text-decoration: none;
    }

    &.entry-meta {
        margin: 0 0 18px;
        list-style: none;
        font-size: 13px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &.entry-meta li {
        display: inline-block;
        margin-right: 16px;
        padding-right: 16px;
        position: relative;
    }

    &.entryItem {
    }

    &.article-copy ul li:before {
        position: absolute;
        left: -1.5rem;
        content: none;
    }

    &.article-copy {
        background-color: $spray-lt-gray;
        padding: 2rem;

        ul {
            margin-top: 2rem;
        }

        &-flex {
            @media #{$medium-up} {
                display: flex;
                margin: 0 auto;
                max-width: 2000px;
            }

            &-main-col {
                @media #{$medium-up} {
                    width: 50%;
                    padding: 1rem;
                }

                @media #{$large-up} {
                    width: 60%;
                    padding: 1.5rem 4rem;
                }
            }

            &-secondary-col {
                padding-top: 2rem;

                @media #{$medium-up} {
                    width: 50%;
                    padding: 1rem;
                }

                @media #{$large-up} {
                    width: 40%;
                    padding: 1.5rem;
                }
            }
        }
    }

    &.pageContainer pagination {
        width: 100%;
    }

    &.author {
        text-align: center;
        font-size: 100%;
        padding: 10px 0px 10px 0px;
    }

    &.avatar img {
        width: 200px;
        aspect-ratio: auto;
        border-radius: 100%;
    }

    &.author-info-part {
        margin: 0 0 10px;
    }

    &.author-info-part h2 {
        margin-top: 0;
        font-size: 3.2rem;
        padding-top: 10px;
    }

    &.author-name {
        font-weight: 700;
        line-height: 1.2;
        word-wrap: break-word;
        letter-spacing: .1px;
        font-size: 3.2rem;
        margin: 0 0 10px;
    }

    &.author-title {
        line-height: 1.2;
        margin: auto;
        max-width: 200px;
    }

    &.author-education {
        margin-left: 1em;
        padding-bottom: 8px;
    }

    &.author-education a {
        line-height: 1.2;
    }

    &.author-social {
        margin-left: 1em;
        display: flex;
    }

    &.author-knowsabout-tags {
        margin-left: 1em;
        display: flex;
        flex-wrap: wrap;
    }

    &.author-knowsabout-tag {
        line-height: 1.2;
        border-radius: 5px;
        padding-bottom: 8px;
        padding-right: 25px;
        padding-left: 0px;
    }
    /*Share Buttons Css Below*/
    &.resp-sharing-button__link,
    &.resp-sharing-button__icon {
        display: inline-block
    }

    &.resp-sharing-button__link {
        text-decoration: none;
        color: #fff;
        padding-right: 10px;
    }

    &.resp-sharing-button {
        border-radius: 5px;
        transition: 25ms ease-out;
        padding: 0.5em 0.75em;
        font-family: Helvetica Neue,Helvetica,Arial,sans-serif
    }

    &.resp-sharing-button__icon svg {
        width: 1em;
        height: 1em;
        margin-right: 0.4em;
        vertical-align: top
    }

    &.resp-sharing-button--small svg {
        margin: 0;
        vertical-align: middle
    }
    /* Non solid icons get a stroke */
    &.resp-sharing-button__icon {
        stroke: #fff;
        fill: none
    }
    /* Solid icons get a fill */
    &.resp-sharing-button__icon--solid,
    &.resp-sharing-button__icon--solidcircle {
        fill: #fff;
        stroke: none
    }

    &.resp-sharing-button--twitter {
        background-color: #55acee
    }

    &.resp-sharing-button--twitter:hover {
        background-color: #2795e9
    }

    &.resp-sharing-button--pinterest {
        background-color: #bd081c
    }

    &.resp-sharing-button--pinterest:hover {
        background-color: #8c0615
    }

    &.resp-sharing-button--facebook {
        background-color: #3b5998
    }

    &.resp-sharing-button--facebook:hover {
        background-color: #2d4373
    }

    &.resp-sharing-button--tumblr {
        background-color: #35465C
    }

    &.resp-sharing-button--tumblr:hover {
        background-color: #222d3c
    }

    &.resp-sharing-button--reddit {
        background-color: #5f99cf
    }

    &.resp-sharing-button--reddit:hover {
        background-color: #3a80c1
    }

    &.resp-sharing-button--google {
        background-color: #dd4b39
    }

    &.resp-sharing-button--google:hover {
        background-color: #c23321
    }

    &.resp-sharing-button--linkedin {
        background-color: #0077b5
    }

    &.resp-sharing-button--linkedin:hover {
        background-color: #046293
    }

    &.resp-sharing-button--email {
        background-color: #777
    }

    &.resp-sharing-button--email:hover {
        background-color: #5e5e5e
    }

    &.resp-sharing-button--xing {
        background-color: #1a7576
    }

    &.resp-sharing-button--xing:hover {
        background-color: #114c4c
    }

    &.resp-sharing-button--whatsapp {
        background-color: #25D366
    }

    &.resp-sharing-button--whatsapp:hover {
        background-color: #1da851
    }

    &.resp-sharing-button--hackernews {
        background-color: #FF6600
    }

    &.resp-sharing-button--hackernews:hover, .resp-sharing-button--hackernews:focus {
        background-color: #FB6200
    }

    &.resp-sharing-button--vk {
        background-color: #507299
    }

    &.resp-sharing-button--vk:hover {
        background-color: #43648c
    }

    &.resp-sharing-button--facebook {
        background-color: #3b5998;
        border-color: #3b5998;
    }

    &.resp-sharing-button--facebook:hover,
    &.resp-sharing-button--facebook:active {
        background-color: #2d4373;
        border-color: #2d4373;
    }

    &.resp-sharing-button--twitter {
        background-color: #55acee;
        border-color: #55acee;
    }

    &.resp-sharing-button--twitter:hover,
    &.resp-sharing-button--twitter:active {
        background-color: #2795e9;
        border-color: #2795e9;
    }

    &.resp-sharing-button--email {
        background-color: #777777;
        border-color: #777777;
    }

    &.resp-sharing-button--email:hover,
    &.resp-sharing-button--email:active {
        background-color: #5e5e5e;
        border-color: #5e5e5e;
    }

    &.resp-sharing-button--linkedin {
        background-color: #0077b5;
        border-color: #0077b5;
    }

    &.resp-sharing-button--linkedin:hover,
    &.resp-sharing-button--linkedin:active {
        background-color: #046293;
        border-color: #046293;
    }
}
