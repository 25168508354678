@import "/fonts/fonts.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.gotham-light {
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    font-weight: 300;
}
.gotham-book {
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    font-weight: 400;
}
.gotham-bold {
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    font-weight: 700;
}