/* ===== HERO-ALT ===== */
.hero-alt {
    &-view {
        padding: 0 16px;
        margin: 0 -16px;
        font: 15px/150% $font-default;
        position: relative;
        z-index: 1;
        background: $color-mist;
        @include media-breakpoint-up(md) {
            padding: 0 20px;
            margin: 0 -20px;
            background: $color-mist;
        }
        @include media-breakpoint-up(xl) {
            font-size: 16px;
            line-height: 160%;
        }

        &::after {
            @include pseudo;
            width: 100vw;
            position: absolute;
            top: 0;
            right: auto;
            bottom: 0;
            left: 50%;
            z-index: -1;
            background-color: $color-maui;
            transform: translateX(-50%);
            @include media-breakpoint-down(sm) {
                right: -16px;
                left: -16px;
                transform: none;
            }
        }
    }

    &-media {
        height: 50vw;
        min-height: 160px;
        max-height: 180px;
        margin: 0 -16px;
        position: relative;
        z-index: 1;
        background-position: center center;
        background-size: cover;
        @include media-breakpoint-up(sm) {
            max-height: 220px;
        }
        @include media-breakpoint-up(md) {
            margin: 0 -20px;
        }

        &.hero-alt-media-mobile {
            display: block;
            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

        &.hero-alt-media-desktop {
            display: none;
            @include media-breakpoint-up(sm) {
                width: 100vw;
                margin: 0;
                display: block;
                left: 50%;
                z-index: 0;
                transform: translate3d(-50%, 0, 0);
            }
            @include media-breakpoint-up(md) {
                height: 31.25vw;
                min-height: 240px;
                max-height: none;
            }
            @include media-breakpoint-up(xl) {
                height: auto;
                min-height: 0;
                margin: 0;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                background-position: center right;
                //background-size: 78% auto;
                background-size: cover;
                background-repeat: no-repeat;

                &::before {
                    @include pseudo;
                    position: absolute;
                    top: 0;
                    right: 20%;
                    bottom: 0;
                    left: 15%;
                    background: linear-gradient(to right, $color-mist 26%, rgba($color-mist, .766) 51%, rgba($color-mist, .5) 72.5%, rgba($color-mist, 0) 100%);
                    @include media-breakpoint-up(xlm) {
                        width: 80%;
                        max-width: 1200px;
                        left: auto;
                        @include media-breakpoint-up(xxl) {
                            width: auto;
                            max-width: none;
                            right: 400px;
                            left: 300px;
                        }
                    }
                }
                &::after {
                    @include pseudo;
                    width: 20%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    background: $color-mist;
                }
            }
        }
    }

    &-content-wrapper {
        @include media-breakpoint-up(xl) {
            min-height: 382px;
            > div {
                min-height: 382px;
                display: flex;
                align-items: center;
            }
        }
    }

    &-content {
        padding: 3rem 0;
        position: relative;
        z-index: 10;
        letter-spacing: .0025em;
        color: $color-black;
        @include media-breakpoint-up(md) {
            padding: 43px 0 42px;
        }
        @include media-breakpoint-up(xl) {
            max-width: 640px;
        }
        @include media-breakpoint-up(xxl) {
            max-width: 720px;
        }

        p {
            margin-bottom: 1rem;
        }

        .hero-btn {
            padding: 16px 16px 14px;
            border-radius: 4px;
            display: inline-block;
            font: 600 13px/18px $font-default;
            letter-spacing: .005em;
            color: $color-white !important;
            text-align: center;
            text-transform: capitalize;
            background: $color-navy;
            transition: background-color $effect;
            box-shadow: inset 0 -4px 0 rgba(black, .2);
            @include media-breakpoint-up(xss) {
                padding-right: 32px;
                padding-left: 32px;
                letter-spacing: .05em;
            }
            @include media-breakpoint-up(sm) {
                padding-right: 42px;
                padding-left: 42px;
                letter-spacing: .075em;
            }
            @include media-breakpoint-up(md) {
                font-size: 14px;
            }

            &:hover, &:focus {
                text-decoration: none;
                background: #004975;
            }

            &.btn1 {
            }

            &.btn2 {
                border: 1.5px solid $color-led-blue;
                color: $color-navy !important;
                background: rgba(white, .8);
                box-shadow: inset 0 -3px 0 rgba(black, .2);
            }

            &.w-465 {
                @include media-breakpoint-up(xl) {
                    min-width: 465px;
                }
            }
        }

        .actions {
            max-width: 440px;
            margin: 24px auto 0;
            @include media-breakpoint-up(md) {
                max-width: 480px;
                margin-top: 32px;
            }
            @include media-breakpoint-up(xl) {
                max-width: none;
                margin-top: 42px;
            }

            &, .video-feature-text-action {
                display: flex;
                flex-direction: column;
                gap: 16px;
                @include media-breakpoint-up(xl) {
                    flex-direction: row;
                    justify-content: flex-start;
                }
            }

            &:empty {
                display: none;
            }
        }

        &.hero-content-theme-1 {
            @media(min-width: 1201px) {
                background: rgba($color-deep-space-blue, .85);
                color: $color-white;

                p {color: $color-white;}

                .hero-alt-title {
                    color: $color-white;
                    span {color: $color-white;}
                }
            }

            .actions {
                > div {
                    .hero-btn {
                        &.btn1 {
                            background: black;
                            &:hover, &:focus {background: #98102e;}
                        }
                        &.btn2 {
                            background: #0065a4;
                            &:hover, &:focus {background: #98102e;}
                        }
                        &.e-commerce-btn {
                            background: black;
                            &:hover, &:focus {background: #98102e;}
                        }
                        &.video-btn {
                            background: #0065a4;
                            &:hover, &:focus {background: #98102e;}
                        }
                    }
                }
            }
        }
    }

    &-title {
        strong {
            font-weight: 600;
        }

        &.hero-alt-title-mobile {
            padding: 3rem 0;
            text-align: center;
            position: relative;
            z-index: 1;
            @include media-breakpoint-up(md) {
                padding: 4rem 0;
            }
            @include media-breakpoint-up(xl) {
                display: none;
            }

            span {
                margin-bottom: 8px;
                display: block;
                font-size: 14px;
                line-height: 1.5;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: .05em;
                color: $color-led-blue;
                @include media-breakpoint-up(xl) {
                    margin-bottom: 16px;
                    font-size: 16px;
                }
            }

            &::after {
                @include pseudo;
                width: 100vw;
                position: absolute;
                top: 0;
                right: auto;
                bottom: 0;
                left: 50%;
                z-index: -1;
                background-color: $color-white;
                transform: translateX(-50%);
                @include media-breakpoint-down(sm) {
                    right: -16px;
                    left: -16px;
                    transform: none;
                }
            }
        }

        &.hero-alt-title-desktop {
            margin: 0 -150px 23px 0;
            font-size: 42px;
            line-height: 50px;
            font-weight: 600;
            letter-spacing: .005em;
            color: black;
            text-transform: uppercase;
            @include media-breakpoint-down(xl) {
                display: none;
            }
            @include media-breakpoint-up(xl) {
                margin-right: -300px;
            }
        }
    }

    &-newtitle {
        margin: 0;
        font-size: 32px;
        line-height: 40px;
        font-weight: 600;
        letter-spacing: -0.0175em;
        color: $color-night;
        @include media-breakpoint-up(xss) {
            font-size: 36px;
            line-height: 44px;
        }
        @include media-breakpoint-up(md) {
            font-size: 42px;
            line-height: 51px;
        }
        @include media-breakpoint-down(md) {
            br {display: none;}
        }
    }

    &-subtitle {
        margin:0 -150px 0 0;
        display: block;
        font-size: 42px;
        line-height: 51px;
        font-weight: normal;
        letter-spacing: .009em;
        text-transform: uppercase;
        color: $color-night;
        @include media-breakpoint-down(xl) {
            display: none;
        }
        @include media-breakpoint-up(xl) {
            margin-right: -300px;
        }

        &:empty {
            display: none !important;
        }

        //&::after {content: ':';}
    }
}