/* ===== FLEX-WRAPPER ===== */
.flex-wrapper {
    width: 100%;
    margin: 0 auto;
    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: center;
    }

    &-container {
        max-width: 1170px;
        margin: 0 auto;
    }

    section.spray-form {
        padding-right: 2rem;
        padding-left: 2rem;
        @include media-breakpoint-up(md) {
            width: 50%;
            margin-bottom: 8rem;
            order: 1;
        }
        @include media-breakpoint-up(lg) {
            width: 58%;
            padding: 0 4rem;
        }
    }

    .wysiwyg-script {
        @include media-breakpoint-up(md) {
            width: 50%;
            order: 1;
        }
        @include media-breakpoint-up(lg) {
            width: 58%;
        }
        @include media-breakpoint-up(xl) {
            padding-right: 3rem;
            padding-left: 3rem;
        }
    }

    .sidebar-cards {
        @include media-breakpoint-up(md) {
            width: 50%;
            padding: 0 1rem;
            order: 2;

            &-grid-item {
                width: 100%;
                padding: 0;

                &-wrapper {
                    .js-toggle-container {height: 0;}
                }
            }
        }
        @include media-breakpoint-up(lg) {
            width: 42%;
            padding: 0 2rem;
        }
        @include media-breakpoint-up(xl) {
            width: 36%;
        }
    }
}
