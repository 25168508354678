// ------------------------------
// = 01 : helper classes
// ------------------------------

.view-on-mobile {
    display: block;
    @include media-breakpoint-up(md) { display: none; }
}
.view-on-desktop {
    display: none;
    @include media-breakpoint-up(md) { display: block; }
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

.strong { font-weight: 700; }
.em { font-style: italic; }

.disable-scrolling { overflow: hidden !important; }

.uppercase {
    letter-spacing: .1rem;
    text-transform: uppercase;
    word-spacing: .1rem;
}
.lowercase { text-transform: lowercase; }

.hide {
    display: none;
    direction: ltr;
    position: absolute;
    text-indent: -9999px;
}
.show { display: inline-block; }
.show-inline { display: inline-block; }
.show-block { display: block; }

.polaroid {
    background: white;
    padding: .9rem .9rem 1.8rem;
}

.elem-center {
    margin: 0 auto;
    text-align: center;
}
.clear { clear: both; }
.fl-right { float: right; }
.fl-left { float: left; }

.txt-center { text-align: center; }
.txt-right { text-align: right; }
.txt-left { text-align: left; }

.outline { outline: 1px solid gold; }

.no-top {
    margin-top: 0 !important;
    padding-top: 0 !important;
}
.no-btm {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.small {
    font-size: .8em;
    line-height: 1.875rem;
    margin-bottom: 1.875rem;
}
.large {
    font-size: 1.2em;
    line-height: 2.5rem;
    margin-bottom: 1.25rem;
}

.quiet { color: #666; }
.loud { color: black; }
.highlight { background: #ff0; }
.added { color: white; background: #060; }
.removed { color: white; background: #900; }

.first {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
}
.last {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
}
.top {
    margin-top: 0;
    padding-top: 0;
}
.bottom {
    margin-bottom: 0;
    padding-bottom: 0;
}


// ------------------------------
// = 06 : leading
//
// adds top margin on element to match vertical grid
// !!! use sparingly !!!
// ------------------------------
.lead-quart { margin-top: .145rem !important; }
.lead-half { margin-top: .25rem !important; }
.lead-0 { margin-top: 0 !important; }
.lead-1 { margin-top: .5rem !important; }
.lead-2 { margin-top: 1rem !important; }
.lead-3 { margin-top: 1.5rem !important; }
.lead-4 { margin-top: 2rem !important; }
.lead-5 { margin-top: 2.5rem !important; }
.lead-6 { margin-top: 3rem !important; }
.lead-7 { margin-top: 3.5rem !important; }
.lead-8 { margin-top: 4rem !important; }
.lead-9 { margin-top: 4.5rem !important; }
.lead-10 { margin-top: 5rem !important; }

@media #{$large-up} {
    .lead-quart { margin-top: .25rem !important; }
    .lead-half { margin-top: .5rem !important; }
    .lead-0 { margin-top: 0 !important; }
    .lead-1 { margin-top: 1rem !important; }
    .lead-2 { margin-top: 2rem !important; }
    .lead-3 { margin-top: 3rem !important; }
    .lead-4 { margin-top: 4rem !important; }
    .lead-5 { margin-top: 5.7rem !important; }
    .lead-6 { margin-top: 6rem !important; }
    .lead-7 { margin-top: 7rem !important; }
    .lead-8 { margin-top: 8rem !important; }
    .lead-9 { margin-top: 9rem !important; }
    .lead-10 { margin-top: 10rem !important; }
}

.bottom-quart { margin-bottom: .145rem !important; }
.bottom-half { margin-bottom: .25rem !important; }
.bottom-0 { margin-bottom: 0 !important; }
.bottom-1 { margin-bottom: .5rem !important; }
.bottom-2 { margin-bottom: 1rem !important; }
.bottom-3 { margin-bottom: 1.5rem !important; }
.bottom-4 { margin-bottom: 2rem !important; }
.bottom-5 { margin-bottom: 2.5rem !important; }
.bottom-6 { margin-bottom: 3rem !important; }
.bottom-7 { margin-bottom: 3.5rem !important; }
.bottom-8 { margin-bottom: 4rem !important; }
.bottom-9 { margin-bottom: 4.5rem !important; }
.bottom-10 { margin-bottom: 5rem !important; }

@media #{$large-up} {
    .bottom-quart { margin-bottom: .25rem !important; }
    .bottom-half { margin-bottom: .5rem !important; }
    .bottom-0 { margin-bottom: 0 !important; }
    .bottom-1 { margin-bottom: 1rem !important; }
    .bottom-2 { margin-bottom: 2rem !important; }
    .bottom-3 { margin-bottom: 3rem !important; }
    .bottom-4 { margin-bottom: 4rem !important; }
    .bottom-5 { margin-bottom: 5.7rem !important; }
    .bottom-6 { margin-bottom: 6rem !important; }
    .bottom-7 { margin-bottom: 7rem !important; }
    .bottom-8 { margin-bottom: 8rem !important; }
    .bottom-9 { margin-bottom: 9rem !important; }
    .bottom-10 { margin-bottom: 10rem !important; }
}