.homepage-header-feature {
    background-position-y: 6rem;
    background-size: 100%;
    max-height: 100%;
    max-width: 100%;
    padding: 3rem 0 !important;

    .is-scrolled & { background-position: center -1rem !important; }

    @media #{$xsmall} {
        background-size: 125%;
    }

    @media #{$medium-up} {
        /* max-height: 340px; */
        background-position-y: 5rem;
        background-size: 110%;
        /* padding: 3rem 0 18rem;*/
    }

    @media #{$large-up} {
        background-size: 100%;
    }

    @media screen and (min-width: 1201px) {
        background-position-y: 13.5rem;
    }


    hr {
        width: 97%;
    }

    &-cta {
        margin-bottom: 5rem;
        max-width: 1005px;

        @media #{$medium-up} {
            margin: 0 auto;
            padding: 4rem 3.5rem;
        }

        @media screen and (min-width: 1061px) {
            padding: 4rem 8rem 0;
        }

        @media #{$xlarge-up} {
            padding: 4rem 3.5rem;
        }

        &-title {
            line-height: 0.85;
            margin-bottom: 1rem;
            text-transform: uppercase;

            @media #{$large} {
                font-size: 4.2rem;
                line-height: 0.8636;
            }

            @media #{$xlarge-up} {
                font-size: 4.2rem;
            }
        }

        &-subhead {
            text-transform: none;

            @media #{$large-up} {
                font-size: 2.6rem;
                letter-spacing: 0.025rem;
                line-height: 1.6;
                margin-bottom: 2rem;
            }

            @media #{$xlarge-up} {
                font-size: 3rem;
            }
        }

        hr {
            margin-bottom: 1rem;

            @media #{$large-up} {
                margin-bottom: 1.5rem;
            }
        }

        .button-blue {
            @media #{$medium-up} {
                bottom: -2.5rem;
            }

            @media #{$large-up} {
                bottom: -3rem;
            }
        }
    }

    .is-scrolled & {
        background-position: center -1rem;
    }
}