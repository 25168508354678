/* ===== HEADER-FEATURE ===== */
.header-feature {
    padding: 5rem 16px !important;
    margin: 0 -16px;
    position: relative;
    font-size: 14px;
    line-height: 1.5;
    color: $color-night;
    background-position: -100px -100px;
    background-repeat: no-repeat;
    background-size: 1px 1px;
    @include media-breakpoint-up(md) {
        padding: 5rem 20px !important;
        margin: 0 -20px;
        font-size: 16px;
    }
    @include media-breakpoint-up(xl) {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }

    &::before, &::after {
        @include pseudo;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: inherit;
        background-color: $color-mist;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        @include media-breakpoint-up(sm) {
            width: 100vw;
            margin-left: -50vw;
            right: auto;
            left: 50%;
        }
    }

    &::before {
        z-index: 1;
        background-position: center -25px;
        @include media-breakpoint-up(md) {
            background-position: center 35px;
            background-attachment: fixed;
            background-size: 110% auto;
        }
        @include media-breakpoint-up(xl) {
            background-position: center -20px;
            background-size: cover;
        }
    }
    &::after {
        z-index: 2;
        background: linear-gradient(0deg, rgba(33, 59, 74, 0.8) 0%, rgba(33, 59, 74, 0.2) 56.58%, rgba(63, 96, 116, 0) 100%);
    }

    &-wrapper {
        max-width: 1290px;
        margin: 0 auto;
        position: relative;
        z-index: 10;

        p {
            margin-bottom: 1.25em;
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;

            &:empty {display: none;}
        }
    }

    &-cta {
        padding: 32px 16px;
        background: rgba($color-mist, .9);
        font: 15px/150% $font-default;
        color: $color-black;
        text-align: center;
        position: relative;
        @include media-breakpoint-up(md) {
            padding: 40px 60px;
        }
        @include media-breakpoint-up(lg) {
            padding-right: 80px;
            padding-left: 80px;
        }
        @include media-breakpoint-up(xl) {
            padding-right: 120px;
            padding-left: 120px;
            font-size: 16px;
            line-height: 1.5;
        }

        &-title {
            margin: 0 0 12px;
            font-weight: 200;
            letter-spacing: .04em;
            color: $color-navy;
            text-transform: uppercase;
            @include media-breakpoint-up(xss) {
                font-size: 40px;
                line-height: 48px;
            }
            @include media-breakpoint-up(xl) {
                font-size: 50px;
            }
        }

        &-subhead {
            margin-bottom: 4px;
            display: block;
            font-size: 14px;
            line-height: 120%;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: .05em;
            color: $color-led-blue;
            @include media-breakpoint-up(md) {
                font-size: 15px;
            }
            @include media-breakpoint-up(xl) {
                margin-bottom: 8px;
                font-size: 16px;
            }

            &:empty {display: none;}
        }

        &-subhead + &-title {
            color: $color-night;
        }

        hr {
            max-width: 79rem;
            padding: 0;
            border-top: 2px dotted rgba($color-ocean, .5);
            margin: 12px auto;
        }

        &-action {
            color: $link-color;
            font-size: inherit;
            font-weight: 600;
            line-height: inherit;
            margin-bottom: 0.33rem;
            padding: 0 1rem;

            @media #{$large-up} {
                font-size: 1.8rem;
            }

            a {
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
            }

            i {
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
            }
        }

        .button-blue {
            padding: 12px 24px;
            border-radius: 4px;
            //margin-top: 24px;
            display: inline-block;
            font: 600 14px/18px $font-default;
            letter-spacing: .05em;
            color: $color-white !important;
            text-transform: none;
            background: $color-navy;
            transition: background-color $effect;
            box-shadow: inset 0 -4px 0 rgba(black, .2);
            @include media-breakpoint-up(md) {
                padding: 16px 32px;
                letter-spacing: .065em;
            }
            @include media-breakpoint-up(xl) {
                padding-right: 52px;
                padding-left: 52px;
            }
            @include media-breakpoint-up(xxl) {
                font-size: 14px;
            }

            &:hover, &:focus {
                text-decoration: none;
                background: #004975;
            }
        }
    }

    //404 specific styles
    &.four-O-four {
        margin-bottom: 1rem;
        background-image: url(../images/header_404page.jpg?cb=2019-03-22);
        background-attachment: fixed;
        background-position: center 40px;
        background-repeat: no-repeat;
        height: auto;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        @include media-breakpoint-up(xl) {
            background-position: center 135px;
        }

        &::after {background: none;}

        .header-feature-cta {
            padding: 5rem 2rem;
            margin: 0 auto;
            background: transparent;
            @include media-breakpoint-up(sm) {
                padding: 8rem 4rem 6rem;
            }
            @include media-breakpoint-up(md) {
                padding: 10rem 6rem 8rem;
            }

            h1, p, h3 {
                color: white;
            }

            h1 {
                font-weight: 100;
                font-size: 4.2rem;
                @include media-breakpoint-up(sm) {
                    font-size: 5rem;
                }
                @include media-breakpoint-up(md) {
                    font-size: 7rem;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 7.5rem;
                }
            }

            h3 {
                font-weight: 500;
                font-size: 2rem;
                letter-spacing: 0.31px;
                margin-bottom: 1rem;
                @include media-breakpoint-up(md) {
                    font-size: 2.2rem;
                }
            }

            p {
                font-weight: 500;
                font-size: 1.5rem;
                @include media-breakpoint-up(xl) {
                    font-size: 1.8rem;
                }
            }

            hr {
                border-top: 2px dotted rgba(255, 255, 255, 0.4);
                @include media-breakpoint-up(md) {
                    max-width: 50rem;
                }
            }

            .button-blue {
                @include media-breakpoint-up(lg) {
                    bottom: -3rem;
                }
            }
        }

        .is-scrolled {
            & {
                background-position: center top;
                @include media-breakpoint-up(xl) {
                    background-position: center 50px;
                }
            }
        }
    }
}


.header-feature-split {
    background: $spray-blue-alt;

    @media #{$large-up} {
        display: flex;
        margin-bottom: 4rem;
    }

    &__media {
        background-size: cover;
        background-position: 50% 50%;
        padding: 33%;

        @media #{$large-up} {
            padding: 0;
            flex: 1;
        }
    }

    &__vmedia {
        padding-top: 0;

        @media #{$large-up} {
            padding: 0;
            flex: 1;
            display: flex;
        }
    }

    &__video {
        aspect-ratio: 16 / 9;
        width: 100%;
        display: flex;
        flex: 1;
    }

    &__content {
        padding: 4.375rem;
        background: $spray-blue-alt;
        color: $light;

        @media #{$large-up} {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
        }

        p {
            color: rgba(255, 255, 255, 0.7);
        }
    }

    &__subhead {
        color: $spray-yellow;
        margin-bottom: 2.5rem;
        font-weight: 400;
        /*style adjustment*/
        &.subhead--light {
            color: $light;
        }

        &.subhead--blue-light {
            color: $spray-ltr-blue;
        }

        &.subhead--green {
            color: $spray-green;
        }
    }

    &__title {
        color: $light;
        margin-bottom: 1.5rem;
        font-weight: 600;
    }

    &__cta {
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
    }
}
