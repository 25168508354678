.prod-tabs-header {
	border-bottom: $dotted-border;
	display: flex;
	width: 100%;
	@media #{$large-up} { padding-left: 2rem; }
	&-item {
		align-items: center;
		color: $link-color;
		cursor: pointer;
		display: flex;
		font-size: 1.4rem;
		font-weight: 600;
		padding: 1rem 1rem;
		position: relative;
		text-align: center;
		user-select: none;
		&:hover { text-decoration: none; } 
		@media screen and (min-width: 375px) { font-size: 1.5rem; }
		@media #{$medium-up} { font-size: 1.7rem; }
		@media #{$large-up} { padding: 1rem 3rem; }
		@media screen and (min-width: 1025px) {
			&:hover { 
				text-decoration: none; 
				&:after {
					@include position(absolute, null 1rem -0.4rem 1rem);
					border-bottom: 6px solid $spray-red-orange;
					content: "";
					display: block;
					height: 6px;
					width: calc(100% - 2rem);
				}
			}
		}
		@media #{$xlarge-up} { font-size: 2rem; }
		&:after {
			@include position(absolute, null 1rem -0.4rem 1rem);
			border-bottom: 6px solid $spray-red-orange;
			content: "";
			display: none;
			height: 6px;
			width: calc(100% - 2rem);
		}
		&.active-tab{
			color: $dark;
			pointer-events: none;
			&:hover { text-decoration: none; }
			&:after { display: block; }
		}
		i {
			color: rgba($spray-dk-blue-green, 0.3);
			font-size: 2rem;
			margin-left: 0.5rem;
			text-align: center;
			&:before {
				position: relative;
				top: 0;
			}
		}
	}
}

.prod-tabs {
	&-item {
		color: $spray-dk-blue-gray;
		font-size: 1.8rem;
		padding: 2rem 0;
		ul { 
			font-weight: 400; 
			margin-left: 5rem;
		}
	}
}