/* ===== CARDS-DEFAULT ===== */
.cards-default {
    &-container {
        margin: 4rem 0;
        position: relative;
        z-index: 1;
        @include media-breakpoint-up(md) {
            margin: 5rem 0;
        }
        @include media-breakpoint-up(xl) {
            margin: 82px 0;
        }

        &.lead-5 {
            margin-top: 4rem !important;
        }

        &-homepage {
            margin: 0;
            padding: 4rem 0;
            @include media-breakpoint-up(md) {
                padding: 5rem 0;
            }
            @include media-breakpoint-up(xl) {
                padding: 8rem 0;
            }

            .cards-default-grid-item-wrapper {
                @include media-breakpoint-up(md) {
                    height: 100% !important;
                }
            }

            &::after {
                @include pseudo;
                width: 100vw;
                position: absolute;
                top: 0;
                right: auto;
                bottom: 0;
                left: 50%;
                z-index: -1;
                background-color: $color-maui;
                transform: translateX(-50%);
                @include media-breakpoint-down(sm) {
                    right: -16px;
                    left: -16px;
                    transform: none;
                }
            }
        }

        .section-header {
            text-align: center;

            &.text-align-left {
                text-align: left;
            }

            h2 {
                &:empty {display: none;}
            }

            small {
                margin-bottom: 8px;
                display: block;
                font-size: 14px;
                line-height: 120%;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: .05em;
                color: $color-led-blue;
                @include media-breakpoint-up(xl) {
                    margin-bottom: 20px;
                    font-size: 16px;
                }
            }

            p {
                &:empty {display: none;}
                &:last-child {margin-bottom: 0;}
            }

            & ~ .cards-default-wrapper {
                margin-top: 2rem;
                @include media-breakpoint-up(md) {
                    margin-top: 42px;
                }
            }
        }
    }

    &-wrapper {
        max-width: 1442px;
        margin: auto;
    }

    &-grid {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        gap: 24px;
        @include media-breakpoint-up(md) {
            padding: 0;
        }

        &-item {
            width: 100%;
            max-width: 420px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            &:last-of-type {margin-bottom: 0;}
            @include media-breakpoint-up(md) {
                width: calc(50% - 12px);
                max-width: 328px;
                margin: 0;
            }
            @include media-breakpoint-up(lg) {
                width: calc(33.3% - 16px);
            }

            > a {
                //height: 100%;
                flex: 1;
                display: block;
                position: relative;

                &:hover, &:focus {
                    text-decoration: none;
                    .cards-default-grid-item-callout-action {color: $color-night;}
                }
            }

            &-wrapper {
                height: auto !important;
                padding: 24px 24px 18px;
                border-radius: 4px;
                position: relative;
                text-align: center;
                background-color: white;
                transition: transform $effect;
                box-shadow: 0 10px 20px -5px rgba($spray-dk-blue-gray, .15), inset 0 0 0 1px rgba($spray-dk-blue-gray, .15);
                @include media-breakpoint-up(md) {
                    min-height: 206px;
                    box-shadow: 0 20px 50px -5px rgba($spray-dk-blue-gray, .15), inset 0 0 0 1px rgba($spray-dk-blue-gray, .15);
                }

                &::before {
                    @include pseudo;
                    height: 5px;
                    border-radius: 2px 2px 0 0;
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    z-index: 5;
                    background: $color-navy;
                    @include media-breakpoint-up(md) {
                        display: block;
                    }
                }

                &.left {
                    text-align: left;
                }

                & > div[sc_item] > i, // icon wrapped in Edit Frame in Experience Editor
                & > i,
                & > a > i {
                    color: $spray-blue;
                    font-size: 6.5rem;
                    margin-bottom: 1rem;
                    @include media-breakpoint-up(lg) {
                        font-size: 7rem;
                        margin-bottom: 1.5rem;
                    }
                    @include media-breakpoint-up(xl) {
                        font-size: 8.2rem;
                    }
                }
            }

            &-title {
                margin-bottom: 14px;
            }

            &-copy {
                font-size: 16px;
                line-height: 1.5;
                color: $color-night;
                opacity: .8;

                p:last-child {margin-bottom: 0;}
            }

            &-callout-action {
                margin-top: .5rem;
                align-items: center;
                display: flex;
                justify-content: center;
                font-size: 14px;
                font-weight: 500;
                color: $color-navy;
                transition: color $effect;
                @include media-breakpoint-up(md) {
                    margin-top: 1rem;
                    font-size: 15px;
                }

                a {
                    color: inherit;
                }

                i {
                    padding-left: .5rem;
                    color: inherit;
                    line-height: 1;
                }
            }

            &-wrapper.left .cards-default-grid-item-callout-action {
                justify-content: flex-start;
            }
        }
    }
}

/* ===== CARDS-PRODUCT ===== */
.cards-product-grid {
    .cards-default-wrapper {
        max-width: none;
    }


    .cards-default-grid-item {
        @include media-breakpoint-up(md) {
            max-width: none;
        }
        &-wrapper {height: auto;}

        &.with-product {
            border-radius: 4px;
            color: $spray-dkr-blue-gray;
            background-color: white;
            box-shadow: 0 10px 20px -5px rgba($spray-dk-blue-gray, .15), inset 0 0 0 1px rgba($spray-dk-blue-gray, .15);
            @include media-breakpoint-up(md) {
                box-shadow: 0 20px 50px -5px rgba($spray-dk-blue-gray, .15), inset 0 0 0 1px rgba($spray-dk-blue-gray, .15);
            }

            .cards-default-grid-item-wrapper, .product-spotlight {
                background: none;
                box-shadow: none;
            }
        }
    }

    .product-spotlight {
        height: auto !important;
        border-radius: 0 0 4px 4px;
        background-color: white;
        box-shadow: 0 10px 20px -5px rgba($spray-dk-blue-gray, .15), inset 0 0 0 1px rgba($spray-dk-blue-gray, .15);
        @include media-breakpoint-up(md) {
            box-shadow: 0 20px 50px -5px rgba($spray-dk-blue-gray, .15), inset 0 0 0 1px rgba($spray-dk-blue-gray, .15);
        }

        &-title {
            width: 100%;
            padding: 3px 24px;
            margin: 0;
            font: 800 12px/14px $font-default;
            letter-spacing: -.025em;
            color: white;
            background: $color-navy;
        }

        &-grid {
            width: 100%;
            max-width: 550px;
            padding: 16px 24px 22px;
            display: block;

            &-image {
                width: 100%;
                max-width: 340px;
                height: 187px;
                padding-right: 0;
                margin-top: 10px;
                margin-bottom: 3px;

                img {
                    width: auto;
                    height: 100%;
                    display: block;
                    object-fit: contain;
                    @include user-select;
                }
            }

            &-copy {
                width: 100%;
                color: $color-night;



                &-title {
                    margin-bottom: 4px;
                    display: block;
                    font-size: 24px;
                    line-height: normal;
                    font-weight: bold;
                    letter-spacing: -.025em;
                }

                &-subtitle {
                    margin-bottom: 18px;
                    display: block;
                    font-size: 14px;
                    line-height: normal;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                &-text {
                    margin-bottom: 0;
                    font-size: 16px;
                    line-height: normal;
                    font-weight: 500;
                    letter-spacing: -.03em;
                    p:last-child {margin-bottom: 0;}
                }

                &-action {
                    margin-top: 14px;
                    display: block;
                    font-size: 15px;
                    font-weight: 500;
                    color: $color-navy;
                    transition: color $effect;

                    a {
                        color: inherit;
                    }

                    i {
                        padding-left: .5rem;
                        color: inherit;
                        line-height: 1;
                    }
                }
            }
        }

        &:hover {
            .product-spotlight-grid-copy-action {
                color: $color-night;
            }
        }
    }
}
