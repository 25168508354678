/* ===== IFRAME ===== */
.iframe-container {
    width: 100%;
    padding: 2rem 0;
    @include media-breakpoint-down(md) {
        overflow: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    @include media-breakpoint-up(md) {
        padding: 3rem 0;
    }
    @include media-breakpoint-up(xl) {
        padding: 10rem 0;
    }

    .spray-iframe {
        @include user-select;
        @include media-breakpoint-up(md) {
            width: 100%;
            display: block;
        }
    }
}