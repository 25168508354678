.resource-grid-header {
    padding: 1rem .25rem;
    //border-top: 1px $dotted-border;
    display: flex;
    font-family: $font-default;
    position: relative;
    z-index: 1;
    background-color: $color-mist;
    @media screen and (max-width: 767px) {
        &.affix {
            @include position(fixed, 0 0 null 0);
            padding: 0 1rem;
        }
    }
    @include media-breakpoint-up(md) {
        padding: 1rem 0;
        justify-content: space-between;
    }

    &-container {
        width: 100%;
    }

    &::before {
        @include pseudo;
        height: 2px;
        border-top: 2px dotted rgba($color-ocean, .5);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }

    &::after {
        @include pseudo;
        width: 100vw;
        position: absolute;
        top: 0;
        right: auto;
        bottom: 0;
        left: 50%;
        z-index: -1;
        background-color: $color-mist;
        transform: translateX(-50%);
        @include media-breakpoint-down(sm) {
            right: -16px;
            left: -16px;
            transform: none;
        }
    }
}

.filter-trigger {
    align-items: center;
    cursor: pointer;
    display: flex;
    user-select: none;
    i {
        align-items: center;
        color: $darker;
        display: flex;
        font-size: 2.5rem;
        height: 30px;
        justify-content: center;
        position: relative;
        transition: cubic-bezier(0.79, 0.24, 0.51, 0.95) 0.3s;
        width: 30px;
    }
    .filter-text {
        display: flex;
        flex-direction: column;
        height: 18px;
        overflow: hidden;
        padding-left: 0.5rem;
        @media #{$large-up} {
            height: 24px;
        }
    }
    .show-filter-text, .hide-filter-text {
        color: $color-navy;
        font-size: 1.4rem;
        font-weight: 500;
        transition: cubic-bezier(0.79, 0.24, 0.51, 0.95) 0.3s;
        @media #{$small-up}{font-size: 1.5rem;}
        @media #{$large-up}{font-size: 1.8rem;}
    }
    .show-filter-text {
        position: relative;
        top: 1px;
    }
    .hide-filter-text {
        position: relative;
        top: 0;
    }
}
.filter-open, .order-filter-open {
    .filter-trigger {
        i { transform: rotate(180deg); }
        .show-filter-text, .hide-filter-text {
            top: -18px;
            @media #{$large-up}{top: -21px;}
        }
    }
}
@media screen and (min-width: 1200px) {
    .resource-grid-header {
        .filter-trigger {
            i { transform: rotate(180deg); }
            .show-filter-text, .hide-filter-text {
                top: -22px;
            }
        }
    }
    .filter-open, .order-filter-open {
        .filter-trigger {
            i { transform: rotate(0); }
            .show-filter-text, .hide-filter-text { top: 1px; }
        }
    }
}
.items-showing {
    color: $spray-dk-blue-gray;
    font-size: 1.4rem;
    line-height: 1.25;
    letter-spacing: 0.17px;
    margin-left: auto;
    @media #{$medium-up} { font-size: 1.6rem; }
    &.number-showing {
        display: none;
        @media screen and (min-width: 375px) { display: block; }
    }

    .keyword {
        font-style: italic;
    }
}
.menu-reset {
    cursor: pointer;
    padding-left: 1rem;
    border-left: 1px solid #ccc;
    user-select: none;
    font-size: 1.4rem;
    font-weight: 600;
    margin-left: 1rem;
    display: inline-block;
    @media screen and (max-width: 374px) { border-left: none; }
    @media #{$small-up} { font-size: 1.5rem; }
    @media #{$large-up} { font-size: 1.8rem; }
    i { font-size: 2.5rem; }
    a {
        position: relative;
        top: 3px;
        @media #{$large-up} { top: 1px; }
    }
    .icon-reorder {
        transition: cubic-bezier(0.79, 0.24, 0.51, 0.95) 0.3s;
    }
    .icon-reorder.active { transform: rotate(360deg); }
}

.keyword-filter-container {
    padding: 1rem 0.5rem;

    @media #{$medium-up} {
        padding-top: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    #keyword-search {
        padding-right: 35px;
    }

    input[type=button].icon-search {
        @include position(absolute, 1px 0.75rem null null);
        background-color: transparent;
        background-image: url(../images/template/magnifying.svg);
        background-position: center 6px;
        background-repeat: no-repeat;
        border: 0;
        box-shadow: none;
        cursor: pointer;
        font-size: 2rem;
        height: 40px;
        outline: 0;
        right: 0px;
        text-indent: -9999px;
        top: 4px;
        width: 38px;
    }
}

// resource grid
.resource-grid {
    //overflow: hidden;
    //padding-bottom: 1rem;
    //@media #{$medium-up} { padding: 0 2rem 0; }
    //@media #{$xlarge-up} { padding: 0 14rem 0; }
    //@media screen and (max-width: 767px) { & .cards-industries-grid-item { margin-bottom: 3rem; } }
    width: 100%;
    padding: 1rem 0;
    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    font-family: $font-default;
    @include media-breakpoint-up(md) {
        padding: 2rem 0;
    }

    &::after {
        @include pseudo;
        width: 100vw;
        position: absolute;
        top: 0;
        right: auto;
        bottom: 0;
        left: 50%;
        z-index: -1;
        background-color: $color-mist;
        transform: translateX(-50%);
        @include media-breakpoint-down(sm) {
            right: -16px;
            left: -16px;
            transform: none;
        }
    }

    &-main {
        width: 100%;

        .cards-industries-grid-item {
            &-title h3 {
                word-break: break-word;
            }
        }
    }

    &-main.six-across {
        padding: 0.5rem;

        .cards-industries-grid-item {
            margin-bottom: 1rem;
            padding: 0 0.5rem;
            width: 50%;

            &-title h3 {
                font-size: 1.8rem !important
            }
        }

        p {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }

    &-sidebar {
        order: 2;
        transition: 0.2s;
        width: 100%;
    }

    .cards-industries-grid-item {
        &-content {
            padding: 1rem 1.4rem !important;
        }

        &-callout-action {
            line-height: 1;

            a {
                font-size: 1.4rem !important;
            }
        }
    }
}

@media #{$medium-up} {
    .resource-grid {
        flex-direction: row;
        &-main {
            order: 2;
            padding-left: 0;
            transition: 0.3s;
            transition-delay: 0s;
            width: 100%;
            .cards-industries-grid-item {
                flex: 0 1 auto;
                max-width: 100%;
                min-width: 1px;
                transition: 0.3s;
                width: 50%;
                &-title h3 { font-size: 2.4rem !important; }
            }
        }
        &-main.six-across {
            .cards-industries-grid-item {
                padding: 0 1rem;
                margin-bottom: 2rem;
                width: 33.33%;
                &-content { padding: 1.6rem 2rem !important; }
                &-wrapper { box-shadow: 0 15px 30px -5px rgba(63, 96, 116, 0.15), inset 0 0 0 1px rgba(63, 96, 115, 0.15); }
            }
            p {
                padding-left: 1rem;
                padding-right: 1rem;
            }
            .resource-card-asset-type { padding: 0.9rem; }
        }
        &-sidebar {
            margin-left: -100%;
            order: 1;
            transition: 0.3s;
            transition-delay: 0s;
            width: 310px;
        }
    }
    html.filter-open {
        .resource-grid {
            &-main {
                width: calc(100% - 310px);
                .cards-industries-grid-item { width: 100%; }
            }
            &-main.six-across {
                .cards-industries-grid-item { width: 50%; }
            }
            &-sidebar { margin-left: 0; }
        }
    }
}

@media #{$large-up} {
    .resource-grid {
        &-main {
            .cards-industries-grid-item {
                width: 33.33%;
            }
        }
        &-main.six-across {
            .cards-industries-grid-item {
                width: 25%;
            }
        }
    }
    html.filter-open {
        .resource-grid {
            &-main {
                .cards-industries-grid-item { width: 50%; }
            }
            &-main.six-across {
                .cards-industries-grid-item {
                    width: 33.33%;
                }
            }
        }

    }
}
@media screen and (min-width: 1201px) {
    .resource-grid {
        &-main {
            width: calc(100% - 310px);
            .cards-industries-grid-item {
                width: 33.3%;
                &-title { font-size: 2.4rem; }
            }
        }
        &-main.six-across {
            .cards-industries-grid-item {
                width: 25%;
            }
        }
        &-sidebar { margin-left: 0; }
    }
    html.filter-open {
        .resource-grid {
            &-main {
                width: 100%;
                .cards-industries-grid-item { width: 25%; }
            }
            &-main.six-across {
                .cards-industries-grid-item {
                    width: 20%;
                }
            }
            &-sidebar { margin-left: -100%; }
        }
    }
}
@media screen and (min-width: 1480px) {
    .resource-grid {
        &-main.six-across {
            .cards-industries-grid-item {
                width: 20%;
            }
        }
    }
    html.filter-open {
        .resource-grid {
            &-main {
                .cards-industries-grid-item { width: 20%; }
            }
            &-main.six-across {
                .cards-industries-grid-item {
                    width: 16.6%;
                }
            }
        }
    }
}

@media screen and (min-width: 1680px) {
    .resource-grid {
        &-main.six-across {
            .cards-industries-grid-item {
                width: 16.6%;
            }
        }
    }
}
@media screen and (min-width: 1800px) {
    .resource-grid-main { width: calc(100% - 370px); }
    .resource-grid-sidebar { width: 370px; }
}
