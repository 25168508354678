.basic-header-feature {
    padding: 3rem 0;
    margin: 0;
    position: relative;
    z-index: 1;
    background: $color-mist;
    @include media-breakpoint-up(md) {
        padding: 4rem 0;
        background: $color-mist;
    }
    @include media-breakpoint-up(xl) {
        font-size: 16px;
        line-height: 160%;
    }

    &::after {
        @include pseudo;
        width: 100vw;
        position: absolute;
        top: 0;
        right: auto;
        bottom: 0;
        left: 50%;
        z-index: -1;
        background-color: $color-maui;
        transform: translateX(-50%);
        @include media-breakpoint-down(sm) {
            right: -16px;
            left: -16px;
            transform: none;
        }
    }


    h1 {
        margin: 0 0 12px;
        color: $color-night;
        text-transform: uppercase;
        @include media-breakpoint-up(md) {
            letter-spacing: .03em;
        }
    }

    hr {
        max-width: 80rem;
        padding: 0;
        border-top: 2px dotted rgba($color-ocean, .5);
        margin: 12px auto;
    }

    p:last-child {margin-bottom: 0;}

    .content-wrapper {
        max-width: 640px;
        padding: 0;
        margin: 0;
        @include media-breakpoint-up(lg) {
            max-width: 720px;
        }
        @include media-breakpoint-up(xl) {
            max-width: 960px;
        }
    }

    &-section {
        margin-bottom: 4px;
        display: block;
        font-size: 14px;
        line-height: 120%;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .05em;
        color: $color-led-blue;
        @include media-breakpoint-up(md) {
            font-size: 15px;
        }
        @include media-breakpoint-up(xl) {
            margin-bottom: 8px;
            font-size: 16px;
        }
    }

    &.centered {
        text-align: center;
        .content-wrapper {
            margin: 0 auto;
        }
    }

    &.prod-detail {
        padding: 3rem 0;
        @media #{$medium-up} { padding: 4rem 0; }
        @media #{$large-up} { padding: 5rem 0; }
        .content-wrapper {
            max-width: 100%;
            padding: 0 2rem;
            @media #{$medium-up} { padding: 0 4rem; }
            @media #{$xlarge-up} { padding: 0 8rem; }
        }
        .prod-detail-type {
            color: #2897dc;
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: 1.75rem;
            text-transform: uppercase;
        }
        h1 {
            margin-bottom: 1.5rem;
            text-align: left;
            text-transform: none;
        }
    }
}