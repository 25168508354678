.nozzle-finder {
	overflow-x: hidden;
}
.main-content {

	.tooltip-overlay  {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(63,96,116, 0.5);
		height: 100%;
		opacity: 0;
		transition: cubic-bezier(0.79, 0.24, 0.51, 0.95) 0.2s;
		visibility: hidden;
		width: 100%;
		z-index: 501;

		&.open {
			visibility: visible;
			opacity: 1;
		}
	}

	.basic-header-feature {
		.nozzle-finder-head {
			margin: 0 auto;
			@media #{$large-up} {
				max-width: 70%;
				padding-bottom: 0;
			}
		}
		&.small-lower-pad { 
			@media #{$large-up} { 
				padding-bottom: 0; 
			}
	
		}
	}
}

.nozzle-finder-search-results {
 	background-color: $spray-lt-gray;
	padding: 1rem;
	@media #{$medium-up} { padding: 3rem; }
	@media #{$xlarge-up} { padding: 5rem; }
	
	&-wrapper {
		margin: 0 auto;

		.swipe-instructions {
			border-bottom: 1px dotted gray;
			line-height: 0;
			margin-bottom: 1.5rem;
			text-align: right;
			margin-left: 0.5rem;
			@media #{$medium-up} {
				display: none;
			}	
			
			span {
				background: $spray-lt-gray;
				font-size: 1.4rem;					
				padding-left: 1rem;
				i {
					font-size: 2.4rem;
					line-height: 0;
					transform: rotate(-90deg);
				}
			}
		}
	}

	h2, p {
		color: $spray-dk-blue-gray;
		text-align: center;
	}

	.red {
		color: $spray-red;
	}

	h2 {
		margin: 3rem 0 1rem;
		@media #{$medium-up} {
			margin: 4rem 0 2rem;
		}
	}

	p {
		padding: 0 1rem;
		font-size: 1.5rem;
		margin: 1rem 0 2rem;
		@media #{$medium-up} {
			font-size: 1.8rem;
			margin: 2rem 0;
			padding: 0 3rem;
		}
	}
}

.nozzle-finder-card-grid-wrapper {
	margin: 0 auto;
}

.nozzle-finder-card-grid {
	display: flex;
	justify-content: flex-start;
	margin: 0 auto;
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
	@media #{$medium-up} { 
		flex-flow: row wrap;
		justify-content: center;
		overflow: hidden;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 0 1px $spray-dk-blue-gray;
		border-radius: 10px;
	}

	&::-webkit-scrollbar {
		margin-top: 2rem;
		height: 12px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $spray-dk-blue-gray;
		border-radius: 10px;
	}
}