.lang-alert {
    display: flex;
    background-color: #003C50;
    color: #eee;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    min-height: calc(1em + 20px);
}

.lang-alert-container {
    max-width: 1493px;
    margin: 0 auto;
    padding: 0 1rem 0 1rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    min-height: 4rem;
}

.lang-alert-text {
    padding: 0 1rem 0 1rem;
    display: flex;
    font-size: 1.7rem;
    flex-direction: column;
    letter-spacing: 0.5px;
    line-height: 1.1;
}

.lang-alert-select {
    -webkit-appearance: none;
    border: none;
    background: #67d1ff url(../images/template/select-dropdown.svg) no-repeat 98% 50%;
    border-radius: 7px;
    color: #fff;
    font-family: "proxima-nova",sans-serif;
    font-size: 1.7rem;
    height: 32px;
    width: 200px;
    outline: 0;
    padding: 0.5rem 0rem 0.5rem 1rem;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
}

.lang-alert-button {
    -webkit-appearance: none;
    font-family: "proxima-nova",sans-serif;
    padding: 0.5rem 1rem 0.5rem 1rem;
    height: 32px;
    border: 0;
    border-radius: 7px;
    color: #000;
    cursor: pointer;
    font-size: 1.7rem;
    letter-spacing: 0.1rem;
    margin-top: 0;
    margin-left: 1rem;
    text-transform: none;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    outline: 0;
    text-align: center;
    text-decoration: none;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.lang-alert-close {
    border: none;
    border-radius: 50%;
    background-color: #fff;
    color: #fff;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    font-size: 1.7rem;
    font-weight: 600;
    display: block;
    position: relative;
    margin-left: 1rem;
    height: 30px;
    width: 25px;
    color: #000;
    cursor: pointer;
}