/* CONTACT */
.calculator-wrapper .main-contact-form {
    padding-bottom: 5.5rem !important;
}

.contact-us-form {
    padding: 0 2rem;
    @include media-breakpoint-up(md) {
        padding: 0;
    }

    .spray-form-field {
        margin-bottom: 2rem;

        &.product-interest {
            margin-top: 3rem;
        }

        fieldset {
            label {
                color: $spray-dk-blue-gray;
                font-size: 1.6rem;
                font-weight: 400;
                margin-left: 0.7rem;
            }
        }
    }
}

.g-recaptcha {
    left: -10px;
    margin-bottom: 3rem;
    position: relative;
    @media screen and (min-width: 330px) {
        position: static;
    }
}