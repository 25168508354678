.order-sum {
	&-container {
		background-color: #f6f8f9;
		padding: 2rem;
		@media #{$medium-up} { padding: 3rem; }
		@media #{$xlarge-up} { padding: 4rem; }
	}
	&-title {
		color: $spray-dk-blue-gray;
		font-size: 1.6rem;
		font-weight: 500;
		letter-spacing: 0.17px;
		margin-bottom: 1rem;
	}
}

.order-sum {
	margin-bottom: 6.5rem;
	&-item {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 0.25rem 0;
		label, span {
			color: $dark;
			font-size: 1.6rem;
			font-weight: 500;
			letter-spacing: 0.21px;
			@media #{$medium-up} { font-size: 1.8rem; }
			@media #{$xlarge-up} { font-size: 2rem; }
		}
		&.order-sum-total { 
			label, span { font-weight: bold; }
		}
	}
	&-divider {
		border-bottom: $dotted-border;
		padding-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
}

.order-pay-method {
	margin-bottom: 4rem;
	&-item {
		align-items: center;
		display: flex;
		span {
			color: $dark;
			font-size: 1.6rem;
			font-weight: 500;
			letter-spacing: 0.21px;
			margin-left: 1rem;
			@media #{$medium-up} { font-size: 1.8rem; }
			@media #{$xlarge-up} { font-size: 2rem; }
		}
	}
}
.order-sum-invoice-button {
	@extend .button-with-icon;
	@extend .button-blue;
}
