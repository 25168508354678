.general-content {
	main {
		background-color: $spray-lt-gray;
	}

	.main-wrapper {
		align-items: flex-start;
		display: flex;
		flex: 0 0 100%;
		flex-direction: row;
		justify-content: center;
		margin: auto;
		max-width: 192rem;
		padding: 3rem 0;
		position: relative;

		@media #{$medium-up} {
			padding: 5rem 0;
		}
		@media #{$large-up} {
			padding: 8rem 0;
		}
	}


	.flex-column-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		@media only screen and (min-width:1024px) and (max-width: 1570px) {
			flex: 0 0 95.4rem;
			width: auto;
		}
		@media only screen and (min-width: 1571px) {
			flex-direction: row;
			width: auto;
		}
	}

	.nav-functional {
		display: none;
		margin: 0;
		padding: 0 2rem;

		@media only screen and (min-width: 1366px) {
			display: block;
			flex: 0 1;
			margin-top: 1rem;
			max-width: 36.2rem;
			min-width: 22.5rem;
		}

		&-container {
			list-style-type: none;
			margin: 0;
			padding: 0 4rem 0 0;

			li {
				display: block;
				margin: 0 0 2.4rem;
				padding: 0;

				a {
					color: $spray-dk-blue-gray;
					font-size: 2.0rem;
					font-weight: 500;
					text-transform: capitalize;
				}

				&.landmark {
					a {
						color: $dark;
						position: relative;

						&:before {
							background-color: $spray-red-orange;
							content: '';
							height: 0.6rem;
							width: 100%;

							@include position(absolute, null null -1rem null);
						}
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	.article {
		flex: 0 0 95.4rem;
		flex-basis: auto; // IE 11 fix
	}

	.article-sidebar {
		flex: 1;
		flex-basis: auto; // ie 11 fix
		margin: 3rem auto 0;
		max-width: 95.4rem;
		padding: 0 2rem;


		@media screen and ( min-width: 1571px) {
			flex: 0 0 36.2rem;
			margin: 0 auto;
			max-width: 36.2rem;
			padding: 0 2rem 0 2rem;
		}

		&-container {
			background-color: $lighter-gray;
			margin: 0;
			padding: 2rem;

			@media screen and ( min-width: 1571px) {
				min-height: 60.2rem;
			}
		}

		p {
			color: $light;
		}
	}
}
