.a11y-slider-prev,
.a11y-slider-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 30px;
    height: 30px;
    color: transparent;
    overflow: hidden;
    border-radius: 50%;
    border: 0;
    background: $dark-1;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border: 2px solid transparent;
        transform: rotate(45deg);
        border-radius: 2px;        
    }
}

.a11y-slider-prev {
    left: 20px;

    &:before {
        border-left-color: $light;
        border-bottom-color: $light;
        margin: 9px 0 0 7px;
    }
}

.a11y-slider-next {
    right: 20px;

    &:before {
        border-top-color: $light;
        border-right-color: $light;
        margin: 9px 0 0 3px;
    }
}

.a11y-slider-dots {
    width: 1384px;
    max-width: calc(100% - 160px);
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    list-style: none;
    display: block;
    margin: 0;

    @media #{$large-up} {
        max-width: calc(100% - 204px);
        bottom: 5rem;
    }

    li {
        margin: 0 0 0 -1px;
        float: left;
        background: $dark-gray-1;
        padding: 0 5px 0 6px;

        &:first-child {
            border-radius: 5px 0 0 5px;
        }

        &:last-child {
            border-radius: 0 5px 5px 0;
        }
    }

    button {
        width: 10px;
        height: 10px;
        padding: 0;
        margin: 0;
        color: transparent;
        overflow: hidden;
        border-radius: 10px;
        background: $light;
        border: 0;
        cursor: pointer;

        &.active {
            width: 25px;
        }
    }
}

.a11y-slider-autoplay-container {
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 1620px;
    max-width: 100%;
    padding-left: 4.375rem;

    @media #{$large-up} {
        padding-left: 70px;
        bottom: 5rem;
    }
}

.a11y-slider-autoplay {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $dark-gray-1;
    background: $light;
    color: transparent;
    overflow: hidden;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        width: 6px;
        height: 8px;
        border-left: 2px solid $dark;
        border-right: 2px solid $dark;
        margin: 6px 1px;
        box-sizing: border-box;
    }

    &[data-autoplaying="false"] {
        &:before {
            width: 0;
            height: 0;
            border-left: 6px solid $dark;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-right: 0;
        }
    }
}

.slider-item {
    background: $spray-lt-gray;

    .header-feature-split__content {
        background: none;
        color: $dark;
        padding-bottom: 6rem;

        .header-feature-split__title {
            color: $dark;
            font-size: 40px;
            font-weight: 700;

            &.title--light {
                color: $light;
            }

            &.title--blue {
                color: $spray-blue-alt-1;
            }

            &.title--green {
                color: $spray-green;
            }

            &.title--yellow {
                color: $spray-yellow;
            }
        }

        p {
            color: $dark;

            &.text--light {
                color: $light;
            }

            &.text--blue {
                color: $spray-blue-alt-1;
            }

            &.text--green {
                color: $spray-green;
            }

            &.text--yellow {
                color: $spray-yellow;
            }
        }
    }

    &.slider-item-theme-1 {
        background: $spray-blue-alt;
    }

    &.slider-item-theme-2 {
        background: $light;
    }

    &.slider-item-theme-3 {
        background: $spray-green;
    }

    &.slider-item-theme-4 {
        background: $spray-light-blue;
    }

    &.slider-item-theme-5 {
        background: $spray-lt-gray;
    }

    @media screen and (max-width: 1200px) {
        height: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }
}

.header-feature-split__media {
    @media screen and (min-width: 1201px) {
        &.media--mobile {
            display: none;
        }
    }

    @media screen and (max-width: 1200px) {
        &.media--desktop {
            display: none;
        }
    }
}

@media #{$large-up} {
    .slider-item {
        position: relative;

        .header-feature-split__content {
            display: flex;
            align-items: center;
            width: 100%;
            margin: auto;
            max-width: 1620px;
            padding-left: 2rem;
            padding-right: 2rem;
            min-height: 620px;

            > div {
                width: 50%;
                padding-right: 8rem;
                padding-left: 8rem;
                position: absolute;
                left: 0;
            }

            .header-feature-split__title {
                font-size: 60px;
                max-width: 100%;
            }
        }

        .header-feature-split__media {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 50%;
        }
    }
}

@media screen and (max-width: 1023px) {
    .a11y-slider-prev,
    .a11y-slider-next {
        display: none;
    }

    .reverse-conetnt-mobile {
        .slider-item {
            display: flex;
            flex-direction: column-reverse;

            .header-feature-split__content {
                padding-bottom: 6rem;
            }
        }
    }
}