/* ===== CTA-BAR ===== */
.cta-bar {
    margin: 4rem 0;
    @include media-breakpoint-up(md) {
        margin: 5rem 0;
    }
    @include media-breakpoint-up(xl) {
        margin: 8rem 0;
    }

    &.bottom-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .cta-content {
        width: 100%;
        padding: 24px 12px;
        //border-radius: 4px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        font: 16px/1.5 $font-default;
        letter-spacing: .01em;
        text-align: center;
        color: $color-night;
        //background: $color-maui;
        @include media-breakpoint-up(sm) {
            padding: 24px;
        }
        @include media-breakpoint-up(xl) {
            padding: 40px;
        }

        &::after {
            @include pseudo;
            width: 100vw;
            position: absolute;
            top: 0;
            right: auto;
            bottom: 0;
            left: 50%;
            z-index: -1;
            background-color: $color-maui;
            transform: translateX(-50%);
            @include media-breakpoint-down(sm) {
                right: -16px;
                left: -16px;
                transform: none;
            }
        }

        p {
            margin-bottom: 0;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
        }

        .main-content.light-gray & {
            background: white;
            box-shadow: 0 10px 20px -5px rgba(63, 96, 116, .15);
            &::after {display: none;}
        }
        .main-content.homepage & {
            &::after {display: none;}
        }
    }

    &-copy {
        display: block;
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        @include media-breakpoint-up(md) {
            max-width: 960px;
            margin: 0 auto;
            font-size: 24px;
            line-height: 28px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 28px;
            line-height: 34px;
        }

        & + p {
            margin-top: 16px;
            @include media-breakpoint-up(xl) {
                margin-top: 21px;
            }
        }
    }

    &-button, .scTextWrapper {
        padding: 12px 24px;
        border-radius: 4px;
        margin-top: 16px;
        display: inline-block;
        font: 600 14px/18px $font-default;
        letter-spacing: .025em;
        color: $color-white !important;
        text-transform: none;
        background: $color-navy;
        transition: background-color $effect;
        box-shadow: inset 0 -4px 0 rgba(black, .2);
        @include media-breakpoint-up(md) {
            padding: 16px 32px;
            margin-top: 24px;
            letter-spacing: .065em;
        }
        @include media-breakpoint-up(xl) {
            padding-right: 52px;
            padding-left: 52px;
            //margin-top: 35px;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 14px;
        }

        &:hover, &:focus {
            text-decoration: none;
            background: #004975;
        }
    }

    &-alt {
        .cta-content {
            color: white;
            background: none;
            &::after {background-color: $spray-dkst-blue;}
        }
    }

    &-white {
        .cta-content {
            background: none;
            &::after {display: none;}
        }
    }
}