/* ===== DEFAULT ===== */
*, *::before, *::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(black, 0);
}
html {
    width: 100%;
    height: 100%;
    font-size: 62.5%;
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

body {
    width: 100%;
    min-width: $min-width;
    min-height: 100%;
    font: 16px/1.5 $font-default;
    color: $color-night;
    background: white;
    @include font-smooth;
    font-optical-sizing: auto;
    text-rendering: optimizeLegibility;
    @include media-breakpoint-up(xl) {
        font-size: 18px;
        line-height: 1.556;
    }
    .menu-open &, .search-open &, .country-language-open & {overflow: hidden;}
}

a {
    color: $color-navy;
    text-decoration: none;
    transition: color $effect;
    outline: none;

    &:hover {
        text-decoration: underline;
    }
}

p {
    margin-top: 0;
}

ul, ol, dl {
    margin-top: 0;
}

ul {
    padding-inline-start: 1.5em;
    li + li {margin-top: .5em;}
    ol {padding-left: 0;}
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
}

h1 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    letter-spacing: -.0175em;
    color: $color-night;
    @include media-breakpoint-up(xss) {
        font-size: 36px;
        line-height: 44px;
    }
    @include media-breakpoint-up(xl) {
        font-size: 42px;
        line-height: 52px;
    }
}

h2 {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    letter-spacing: .01em;
    color: $color-night;
    @include media-breakpoint-up(md) {
        font-size: 28px;
        line-height: 32.8px;
    }
}

h3 {
    font-size: 20px;
    line-height: 23.5px;
    font-weight: 500;
    letter-spacing: .0125em;
    color: $color-night;
    @include media-breakpoint-up(xl) {
        font-size: 21px;
        line-height: 24.7px;
    }
}

h4 {
    color: $dark;
    font-size: 1.8rem;
    font-weight: 900;
    letter-spacing: -0.55px;
    line-height: 1.2;
    margin-bottom: 2rem;

    @media #{$large-up} {
        font-size: 2.4rem;
    }
}

h5 {
    color: $dark;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
    text-transform: uppercase;

    @media #{$small-up} {
        font-size: 1.8rem;
    }
}

img {
    max-width: 100%;
    height: auto;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

figure {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
}

/*
ul {
    font-weight: 400;
    line-height: 1.5;
    list-style-position: outside;
    list-style-type: disc;
    margin-bottom: 2.4rem;
    margin-left: 3.6rem;

    li {
        margin-bottom: 1.2rem;
    }
}

ol {
    font-weight: 400;
    line-height: 1.5;
    list-style-position: outside;
    list-style-type: decimal;
    margin-bottom: 2.4rem;
    margin-left: 3.6rem;

    li {
        margin-bottom: 1.2rem;
    }
}

ul ul,
ol ul {
    list-style-position: outside;
    list-style-type: circle;
    margin-left: 1.6rem;
}

ol ol,
ul ol {
    list-style-position: outside;
    list-style-type: lower-latin;
    margin-left: 1.6rem;
}
 */

hr {
    height: 0;
    border: 0;
    border-top: 2px dotted #e3e7e9;
    margin: 0 auto;
    background: none;
}

/*
ul {
    list-style-type: disc;
    margin-left: 2.4rem;
}
 */

blockquote {
    border-color: $spray-red-orange;
    border-style: solid;
    border-width: 0 0 0 0.6rem;
    color: $spray-dk-blue-gray;
    display: block;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: -0.5px;
    line-height: 1.06;
    margin: 3rem 0;
    padding: 0 2rem;
    position: relative;
    quotes: none;
    @media #{$medium-up} {
        font-size: 3.4rem;
        margin: 4rem 0;
    }
    @media #{$large-up} {
        font-size: 3.8rem;
    }


    p {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }

    .end-quote {
        color: $spray-red;
    }

    &:before, &:after {content: '';content: none;}
}

sub, sup {
    /* Specified in % so that the sup/sup is the right size relative to the surrounding text */
    font-size: 60%;
    /* Zero out the line-height so that it doesn't interfere with the positioning that follows */
    line-height: 0;
    /* Where the magic happens: makes all browsers position the sup/sup properly, relative to the surrounding text */
    position: relative;
}
sup {
    /* Move the superscripted text up */
    font-weight: 100;
    top: -0.5em;
    vertical-align: baseline;
}
sub {
    /* Move the subscripted text down, but only
       half as far down as the superscript moved up */
    bottom: -0.25em;
}


.large-paragraph,
.intro {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.45;
    margin-bottom: 2.4rem;

    @media #{$small} {
        font-size: 2rem;
    }

    @media #{$medium-up} {
        font-size: 2.125rem;
        line-height: 1.5;
    }

    @media #{$large-up} {
        font-size: 2.4rem;
    }
}

.has-arrow::after {
    content: "\e90e";
    font-family: 'icomoon' !important;
    position: absolute;
}

.fa {
    font-family: $font-fa;
}